@charset "UTF-8";
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video,
button {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: top;
	background: transparent;
}

html {
	height: 100% !important;
	touch-action: manipulation;
	-ms-touch-action: manipulation;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	margin: 0;
	padding: 0;
}
#root {
	/*padding-bottom: 74px !important;*/
}
body {
	font-family: 'M PLUS 1p', sans-serif, Lato, Meiryo, "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: #333;
	width: 100%;
	background-color: #fff;
	font-size: 14px;
	word-wrap: break-word;
	min-height: 100%;
	padding-top: 32px;
	padding-bottom: 74px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

.clearfix:after {
	clear: both;
	display: block;
	content: "";
}

video {
	width: 100%;
	height: 100%;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

a {
	color: #077CF8;
	text-decoration: none;
}

img {
	max-width: 100%;
	vertical-align: middle;
}

ul {
	margin: 0;
	list-style: none;
}

ul:after {
	clear: both;
	display: block;
	content: "";
}


/*** table ***/

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}


/*
  table.table-striped tr:nth-of-type(2n+1) {
    background-color: #F9F9F9; }*/

table.table-box th {
	background-color: #445466;
	color: #fff;
}

table th,
table td {
	padding: 4px;
	border: 1px solid #DDD;
	vertical-align: middle;
}

table tr.active-box td {
	background: #E4F3EF;
}


/*
table tr.active-box td {
  position: relative;
}
table tr.active-box td:after {
  position: absolute;
  left: -1px;
  top: -1px;
  height: 100%;
  width: 100%;
  content: "";
  border-top: 1px #1ABC9C solid;
  border-left: 1px #1ABC9C solid;
  border-bottom: 1px #1ABC9C solid;
  display: block;
}
table tr.active-box td:last-child:after {
  border-right: 1px #1ABC9C solid;
}
*/

.fixed-table-container {
	height: 300px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px #ccc solid;
}

.fixed-table-container tr:last-child td {
	border-top: none;
}

.fixed-table-container tr td,
.fixed-table-container tr th {
	border-top: none;
	border-left: none;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	white-space: nowrap;
}

.wrap {
	min-width: 1016px;
	margin: 0 auto;
}

.container-fluid {
	margin: 0 auto;
	/*
  max-width: 1024px;
  */
	padding: 0 16px;
}

.container-wrap {
	margin: 0 auto;
	max-width: 1170px;
}


/** margin **/

.mt-8 {
	margin-top: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-64 {
	margin-top: 64px;
}

.mt-80 {
	margin-top: 80px;
}

.mb-8 {
	margin-bottom: 8px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-24 {
	margin-bottom: 24px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-64 {
	margin-bottom: 64px;
}

.mb-80 {
	margin-bottom: 80px;
}

.ml-8 {
	margin-left: 8px;
}

.mr-8 {
	margin-right: 8px;
}

.ml-16 {
	margin-left: 16px;
}

.mr-16 {
	margin-right: 16px;
}


/** padding **/

.pt-8 {
	padding-top: 8px;
}

.pt-16 {
	padding-top: 16px;
}

.pt-24 {
	padding-top: 24px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-64 {
	padding-top: 64px;
}

.pt-80 {
	padding-top: 80px;
}

.pb-8 {
	padding-bottom: 8px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pb-24 {
	padding-bottom: 24px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-64 {
	padding-bottom: 64px;
}

.pb-80 {
	padding-bottom: 80px;
}

.color-danger {
	color: #ff3b30;
	font-size: 12px;
}

.color-blue {
	color: #738BA2;
}


/** label-span **/

.label-span {
	border-radius: 15px;
	color: #fff;
	padding: 1 12px 0;
	text-align: center;
	font-size: 12px;
}

.label-span.label-red {
	color: #fff;
	background-color: #E27DA0;
}


/** page **/

.page ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.page ul li:last-child a {
	border-radius: 0 4px 4px 0;
	color: #077CF8;
}

.page ul li:first-of-type a {
	border-radius: 4px 0 0 4px;
	color: #777;
}

.page ul li span,
.page ul li a {
	background: #DDD;
	display: block;
	padding: 4px 16px;
	color: #73879C;
	text-decoration: none;
}

.page ul li span {
	color: #fff;
}

.wrapper .main {
	width: calc(100% - 230px);
	float: right;
}

.title {
	border-bottom: solid 1px #707070;
}

.tlt-fs28 {
	color: #73879C;
	font-size: 28px;
	font-weight: 600;
}

.tlt-fs24 {
	color: #73879C;
	font-size: 24px;
	font-weight: 600;
}

.tlt-fs18 {
	color: #73879C;
	font-size: 18px;
	font-weight: 600;
}

.tablefixedhead {
	border-bottom: 1px solid #ddd;
}

.tablefixedhead .box-tbodytable .table tbody tr:last-child td {
	border-bottom: 0;
}

.tablefixedhead .box-theadtable {
	overflow: hidden;
}

.tablefixedhead .box-tbodytable {
	max-height: 500px;
	overflow: auto;
	position: relative;
}

.box-tbodytable tr:nth-of-type(2n+2) {
	background-color: #f9f9f9;
}

.tablefixedhead .table-codelist thead tr th {
	padding: 5px 10px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(1) {
	min-width: 60px;
	max-width: 60px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(2) {
	min-width: 150px;
	max-width: 150px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(3) {
	min-width: 200px;
	max-width: 200px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(4) {
	min-width: 250px;
	max-width: 250px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(5) {
	min-width: 100px;
	max-width: 100px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(6) {
	min-width: 100px;
	max-width: 100px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(7) {
	min-width: 70px;
	max-width: 70px;
}

.tablefixedhead .table-codelist tbody tr td {
	padding: 5px 10px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(1) {
	min-width: 60px;
	max-width: 60px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(2) {
	min-width: 150px;
	max-width: 150px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(3) {
	min-width: 200px;
	max-width: 200px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(4) {
	min-width: 250px;
	max-width: 250px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(5) {
	min-width: 100px;
	max-width: 100px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(6) {
	min-width: 100px;
	max-width: 100px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(7) {
	min-width: 70px;
	max-width: 70px;
}


/*** base ***/

.datepicker {
	padding-bottom: 1px !important;
}

.datepicker .table-condensed thead tr th.dow {
	font-size: 14px;
	color: #26B99A;
}

.datepicker .table-condensed thead tr th.dow:first-child {
	color: #ff0000;
}

.datepicker .table-condensed thead tr th.dow:last-child {
	color: #000AFF;
}

.datepicker .table-condensed tbody tr td {
	font-size: 14px;
	height: 28px;
	width: 28px;
	padding: 2px;
}

.datepicker .table-condensed tbody tr td:first-child {
	color: #ff0000;
}

.datepicker .table-condensed tbody tr td:last-child {
	color: #000AFF;
}

.datepicker .table-condensed tfoot tr {
	border-top: 1px solid #ccc;
}

.datepicker .table-condensed tfoot tr th {
	cursor: default;
}

.datepicker .table-condensed tfoot tr th:hover {
	background: transparent;
}

.datepicker .table-condensed tfoot tr th .groupfooter:after {
	content: "";
	display: block;
	clear: both;
}

.datepicker .table-condensed tfoot tr th .groupfooter .textleft {
	float: left;
	font-size: 12px;
	color: #000AFF;
	font-weight: normal;
	line-height: 24px;
	cursor: pointer;
}

.datepicker .table-condensed tfoot tr th .groupfooter .button-calendar {
	float: right;
}

.datepicker .table-condensed tfoot tr th .groupfooter .button-calendar .btn-green-small {
	font-size: 12px;
	padding: 2px 8px;
	height: auto;
}


/*** modules ***/


/***/

.iris_toggle-container {
	-moz-user-select: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	position: relative;
}

.iris_toggle-container label {
	cursor: pointer;
	margin: 0;
}

.iris_toggle-container .iris_toggle-checkbox {
	opacity: 0;
	position: absolute;
}

.iris_toggle-container .iris_toggle-checkbox:checked+.iris_toggle:before {
	left: 55%;
	background: #fff;
}

.iris_toggle-container .iris_toggle-checkbox:checked+.iris_toggle {
	background: #1ABC9C;
}

.iris_toggle-container .iris_toggle {
	border-radius: 16px;
	display: inline-block;
	padding: 12px 0;
	vertical-align: middle;
	background: #999;
	width: 66px;
	height: 32px;
	position: relative;
	-moz-appearance: none;
	outline: 0 none;
	overflow: hidden;
}

.iris_toggle-container .iris_toggle:before {
	background: #fff;
	border-radius: 50%;
	content: "";
	display: block;
	left: 2px;
	width: 28px;
	height: 28px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
	-o-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
	transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
}

.btn {
	-moz-user-select: none;
	border: 1px solid transparent;
	border-radius: 4px;
	display: inline-block;
	text-align: center;
	-webkit-transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	-o-transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	vertical-align: middle;
	white-space: nowrap;
	font-size: 15px;
	padding: 0 16px;
	height: 30px;
	-webkit-transition: 200ms all;
	-o-transition: 200ms all;
	transition: 200ms all;
}

.btn:hover,
.btn:focus {
	text-decoration: none;
	opacity: 0.7;
}

.btn:focus,
.btn.focus {
	-webkit-box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
	box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
	outline: 0 none;
}

.btn.disabled,
.btn:disabled {
	opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.btn-primary {
	background-color: #187cc2;
	border: 1px #187cc2 solid;
	color: #fff;
}

.btn-green {
	background-color: #26B99A;
	border: 1px #26B99A solid;
	color: #fff;
}

.btn-gray, .modal-visit .btn-gray {
	background-color: #F5F5F5;
	border: 1px #169F85 solid;
	color: #000;
}

.btn-red {
	background-color: #FF9999;
	border: 1px #707070 solid;
	color: #000;
}

.btn-red-fl {
	background-color: #DE8282;
	border: 1px #DE8282 solid;
	color: #fff;
}

.btn-yellow {
	background-color: #CC9F07;
	border: 1px #707070 solid;
	color: #000;
}

.btn-purple {
	background-color: #A2A2CE;
	border: 1px #A2A2CE solid;
	color: #fff;
}

.btn-redfl {
	background-color: #F5F5F5;
	border: 1px #FF9999 solid;
}

.btn-golden {
  background-color: #FCC80F;
  border: 1px #FCC80F solid;
  color: #000; 
}
.btn-golden:hover:not(:disabled):not(.disabled) {
  background-color: #E7B915;
  border: 1px #E7B915 solid;
  color: #000;
  opacity: unset !important;
}
.btn-golden:disabled {
  color: #fff; 
}

.btn-dark-brown {
  background-color: #231815;
  border: 1px #231815 solid;
  color: #fff; 
}
.btn-dark-brown:hover:not(:disabled):not(.disabled) {
  background-color: #fff;
  border: 1px #231815 solid;
  color: #231815; 
  opacity: unset !important;
}

.btn-unfous:focus{
  opacity: 1;
}

/**** update file ***/

.custom-file {
	position: relative;
	padding: 0;
}

.custom-file .btn {
	height: auto;
}

.custom-file .btn.btn-txt {
	color: #187cc2;
	font-size: 10px;
	margin-top: 4px;
}

.custom-file-input {
	opacity: 0;
	display: none;
}

input,
button,
select,
optgroup,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
}

.form-control {
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
	display: block;
	font-size: 14px;
	line-height: 1.6;
	-webkit-transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	-o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	width: 100%;
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	appearance: none;
	height: 30px;
	border-radius: 3px;
	padding: 0 8px;
}

.form-control.form-control-search {
	background-image: url(../../images/icon-search.png);
	background-repeat: no-repeat;
	background-position: right 8px center;
	padding-right: 24px;
}

.form-control.form-control-date {
	background-image: url(../../images/icon-date.png);
	background-repeat: no-repeat;
	background-position: right 8px center;
	padding-right: 24px;
}

.form-control.form-control.form-control-time {
	background-image: url(../../images/icon-time.png);
	background-repeat: no-repeat;
	background-position: right 8px center;
	padding-right: 24px;
}

.form-control.form-controlbox-yellow {
	background: #FFFDCB;
}

.form-control:focus {
	background-color: #fff;
	border-color: #80bdff;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	color: #495057;
	outline: 0 none;
}

.form-control.form-control-danger {
	border: 1px #ff3b30 solid;
	background: #ffeae8;
}

textarea {
	overflow: auto;
}

textarea.form-control {
	height: auto;
}

select::-ms-expand {
	display: none;
}

select.form-control {
	background: url(../../images/select.png) no-repeat scroll right 6px center #fff;
	padding-right: 20px;
}

select.form-controlbox-yellow {
	background: url(../../images/select.png) no-repeat scroll right 6px center #FFFDCB;
}

select.form-controlbox-yellow:focus::-ms-value {
	background: url(../../images/select.png) no-repeat scroll right 6px center #FFFDCB;
}

select.form-control-danger {
	background: url(../../images/select.png) no-repeat scroll right 6px center #ffeae8 !important;
}

select.form-control-danger:focus::-ms-value {
	background: url(../../images/select.png) no-repeat scroll right 6px center #ffeae8;
}


/* select.form-control-blue {
  background: url(../../images/select-blue.png) no-repeat scroll right 6px center #fff;
  padding-right: 20px; }
  select.form-control-blue.form-controlbox-blue-yellow {
    background: url(../../images/select-blue.png) no-repeat scroll right 6px center #FFFDCB; } */

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}


/**** radio checkbox ****/

.custom-control {
	cursor: pointer;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	min-height: 18px;
	padding-left: 24px;
	position: relative;
}

.custom-control-input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.custom-control-input:checked~.custom-control-indicator {
	background-color: #1ABC9C;
	border: 1px #1ABC9C solid;
	color: #fff;
}

.custom-control-input:focus~.custom-control-indicator {
	-webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(26, 188, 156, 0.25);
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(26, 188, 156, 0.25);
}

.custom-control-input:active~.custom-control-indicator {
	background-color: #1ABC9C;
	border: 1px #1ABC9C solid;
	color: #fff;
}

.custom-control-input:disabled~.custom-control-indicator {
	background-color: #eee;
	border: 1px #ccc solid;
	cursor: not-allowed;
}

.custom-control-input:disabled~.custom-control-description {
	color: #636c72;
	cursor: not-allowed;
}

.custom-control-indicator {
	background-color: #fff;
	border: 1px #ccc solid;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 50% 50%;
	display: block;
	height: 18px;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: calc(50% - 10px);
	width: 18px;
}

.custom-checkbox {
	min-height: 20px;
}

.custom-checkbox .custom-control-indicator {
	width: 20px;
	height: 20px;
}

.custom-checkbox .custom-control-indicator {
	border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-indicator {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-indicator {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
	background-color: #1ABC9C;
}

.custom-radio .custom-control-indicator {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-indicator {
	background: url(../../images/icon-radio.png) no-repeat 50%;
	border: 1px #1abc9c solid;
}
.custom-radio.checked .custom-control-indicator {
	background: url(../../images/icon-radio.png) no-repeat 50%;
	border: 1px #1abc9c solid;
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	color: #000;
	text-decoration: none;
	opacity: .75;
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
}

.modal-open {
	overflow: hidden;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: -webkit-transform 0.3s ease-out;
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-o-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}

.modal.show .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-dialog-centered {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - (0.5rem * 2));
	content: "";
}

.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	width: 100%;
	margin: 0 16px;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 0.3rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: start;
	-webkit-box-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid #e9ecef;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.modal-header .close {
	padding: 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flex-pack: end;
	-webkit-box-pack: end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #e9ecef;
}

.modal-footer> :not(:first-child) {
	margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
	margin-right: .25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}
	.modal-dialog-centered::before {
		height: calc(100vh - (1.75rem * 2));
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-toggle::after {
	vertical-align: 0;
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropleft .dropdown-toggle::after {
	display: none;
}

.dropleft .dropdown-toggle::before {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #6c757d;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-item {
	position: relative;
	display: none;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
	transition: -webkit-transform 0.6s ease;
	-webkit-transition: -webkit-transform 0.6s ease;
	-o-transition: transform 0.6s ease;
	transition: transform 0.6s ease;
	transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}

.carousel-item-next,
.carousel-item-prev {
	position: absolute;
	top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-next.carousel-item-left,
	.carousel-item-prev.carousel-item-right {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.carousel-item-next,
.active.carousel-item-right {
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-next,
	.active.carousel-item-right {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}

.carousel-item-prev,
.active.carousel-item-left {
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-prev,
	.active.carousel-item-left {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}

.carousel-fade .carousel-item {
	opacity: 0;
	-webkit-transition-duration: .6s;
	-o-transition-duration: .6s;
	transition-duration: .6s;
	-webkit-transition-property: opacity;
	-o-transition-property: opacity;
	transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-fade .carousel-item-next,
	.carousel-fade .carousel-item-prev,
	.carousel-fade .carousel-item.active,
	.carousel-fade .active.carousel-item-left,
	.carousel-fade .active.carousel-item-prev {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 15%;
	color: #fff;
	text-align: center;
	opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: transparent no-repeat center center;
	background-size: 100% 100%;
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
}

.carousel-indicators li {
	position: relative;
	-ms-flex: 0 1 auto;
	-webkit-box-flex: 0;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
	position: absolute;
	top: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: "";
}

.carousel-indicators li::after {
	position: absolute;
	bottom: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: "";
}

.carousel-indicators .active {
	background-color: #fff;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
}


/*** modules ***/
.pd {
  padding: 16px;
}

.pd-tb {
  padding: 16px;
}

.pd-x {
  padding: 0 16px;
}

.clr-blue {
  color: #73879C;
}

.box-bod {
  /*border: 1px #eee solid;*/
  min-height: calc(100vh - 220px);
}

.box-bod.box-hg {
  min-height: calc(100vh - 167px);
}

.color-gray {
  color: #606060;
}

.icon-keyed {
  background: url("/images/ic_key.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.icon-keyed-disabled {
	background: url("/images/ic_key_disable.png") no-repeat center;
	width: 24px;
	height: 24px;
	display: inline-block;
	cursor: context-menu;
}

.icon-searchgreen {
  background: url("/images/icon_searchgreen.png") no-repeat center;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.icon-searchgreen.disabled {
  background: url("/images/audittrail_disable.png") no-repeat center;
}

.icon-pencil {
  background: url("../../images/ic_pencil.png") no-repeat center;
  background-size: 15px;
  width: 15px;
  height: 17px;
  display: inline-block;
  cursor: pointer;
}

.icon-pencil-erase {
  background: url("../../images/ic_pencil-erase.png") no-repeat center;
  background-size: 15px;
  width: 15px;
  height: 17px;
  display: inline-block;
  cursor: pointer;
}

.btn-modal .btn {
  min-width: 120px;
}

#login {
  background: url("/images/Image2.png") no-repeat;
  background-position: left bottom;
  background-size: 60%;
  height: 100vh;
  margin: -32px 0 -74px;
}

.page-information .tbl-table tr th {
  text-align: left;
  color: #738BA2;
  min-width: 200px;
  background: #F5F5F5;
}

.page-information .tbl-table tr .th-head {
  text-align: left;
  color: #738BA2;
  min-width: 200px;
  background: #EDF4FD;
}

.page-information .tbl-table tr td {
  width: 80%;
}

.page-information .tbl-table tr td .custom-control {
  margin-right: 16px;
}

.page-information .tbl-table tr td .custom-control:last-child {
  margin-right: 0;
}

.login .logo-login {
  text-align: center;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

.login .logo-login:after {
  position: absolute;
  top: calc(50% - -5px);
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background: #0B0A0A;
}

.login .logo-login a {
  background: #fff;
  padding: 0 24px;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.login .form-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.login .group-form {
  max-width: 800px;
  min-width: 500px;
  margin-top: 128px;
}

.login .group-form .group-form-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.login .group-form .group-form-input label {
  margin-top: 3px;
  width: 120px;
  color: #73879C;
  font-weight: bold;
}

.login .group-form .group-form-input .group-form-fl {
  width: calc(100% - 120px);
  position: relative;
}

.login .group-form .group-form-input .group-form-fl .form-control {
  padding-left: 48px;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.login .group-form .group-form-input .group-form-fl span {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 40px;
  height: 28px;
  background: #EEE;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login .group-form .group-form-input .group-form-fl span img {
  max-height: 20px;
}

.login .group-form .group-form-input .clr-blue {
  text-decoration: underline;
}

.login .group-form .btn-login {
  margin-left: 120px;
}

.forgot-password .box-form {
  border: solid 1px #738BA2;
  padding: 40px;
  margin-top: 120px;
  position: relative;
}

.forgot-password .box-form .item-lock {
  position: absolute;
  top: -36px;
  left: calc(50% - 50px);
  background: #fff;
  width: 100px;
  text-align: center;
}

.forgot-password .form-forgot-password {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.forgot-password .form-forgot-password .form-left {
  width: 250px;
  padding-top: 8px;
}

.forgot-password .form-forgot-password .form-left label {
  font-weight: 600;
  color: #738BA2;
}

.forgot-password .form-forgot-password .form-right {
  width: calc(100% - 250px);
  padding-left: 24px;
}

.forgot-password .btn-forgot-password {
  margin-left: 0;
}

.forgot-password .btn-forgot-password .btn {
  min-width: 110px;
}

.form-search.form-search-fl ul li .form-control-fl label {
  width: 80px;
  padding-right: 8px;
}

.form-search.form-search-fl ul li .form-control-fl .form-control {
  width: calc(100% - 80px);
}

.form-search ul {
  margin: 0 0 0 -24px;
}

.form-search ul li {
  margin-bottom: 16px;
  padding-left: 24px;
  float: left;
  width: 25%;
}

.form-search ul li:nth-of-type(4n+1) {
  clear: both;
}

.form-search ul li .form-control-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-search ul li .form-control-fl label {
  width: 70px;
  padding-right: 8px;
}

.form-search ul li .form-control-fl .form-control {
  width: calc(100% - 70px);
}

.form-search .form-btn {
  /*display: inline-flex;*/
}

.form-search .form-btn .btn {
  min-width: 125px;
}

.visit-status-page .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 254px);
}

.visit-status-page .tablefixedhead .table-visit-status tr th,
.visit-status-page .tablefixedhead .table-visit-status tr td {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
}

.visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(1), .visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(8), .visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(10), .visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(12), .visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(14), .visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(16),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(1),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(8),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(10),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(12),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(14),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(16) {
  max-width: 40px;
  min-width: 40px;
}

.visit-status-page .tablefixedhead .table-visit-status tr th:nth-child(6),
  .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(6) {
  max-width: 140px;
  min-width: 140px;
}

.visit-status-page .tablefixedhead .table-visit-status tr th {
  text-align: center;
}

.visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(6), .visit-status-page .tablefixedhead .table-visit-status tr td:nth-child(7) {
  text-align: right;
}

.visit-status-page .tablefixedhead .table-visit-status tr img {
  max-height: 13px;
}

.visit-status-intro-page {
  /** code update **/
}

.visit-status-intro-page .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 224px);
}

.visit-status-intro-page .tablefixedhead .table-visit-status-fl tr th,
  .visit-status-intro-page .tablefixedhead .table-visit-status-fl tr td {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
}

.visit-status-intro-page .tablefixedhead .table-visit-status-fl tr th:nth-child(1),
    .visit-status-intro-page .tablefixedhead .table-visit-status-fl tr td:nth-child(1) {
  max-width: 200px;
  min-width: 200px;
}

.visit-status-intro-page .tablefixedhead .table-visit-status-fl tr td:nth-child(1) {
  text-align: left;
}

.visit-status-intro-page .tablefixedhead .table-visit-status-fl tr th span {
  font-size: 24px;
}

.visit-status-intro-page .item-status ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.visit-status-intro-page .item-status ul li {
  padding: 0 4px;
}

.visit-status-intro-page .item-status img {
  max-height: 13px;
}

.visit-status-intro-page .grouptwotablefixedhead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
    /*
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    */
}

.visit-status-intro-page .grouptwotablefixedhead .boxtableleft {
  width: 300px;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtableleft .box-theadtable .div-th {
  font-size: 24px;
  width: 100%;
  border-right: 1px solid #ddd;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtableleft .box-tbodytable01 {
  max-height: calc(100vh - 230px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  z-index: 1;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtableleft .box-tbodytable01 .div-tbody .div-tr {
  border-right: 1px solid #ddd;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtableleft .box-tbodytable01 .div-tbody .div-tr .div-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll {
  border-right: 1px solid #ddd;
  min-width: 100px;
  width: auto;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-theadtable {
  overflow: hidden;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-theadtable .div-tr .div-th:first-of-type {
  border-left: none;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-theadtable .div-tr .div-th span {
  max-height: 24px;
  overflow: hidden;
  display: block;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-tbodytable {
  max-height: calc(100vh - 230px);
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-tbodytable .div-tr .div-td {
  min-width: 160px;
}

.visit-status-intro-page .grouptwotablefixedhead .boxtablescroll .box-tbodytable .div-tr .div-td:first-of-type {
  border-left: none;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-thead .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-thead .div-tr .div-th {
  background: #445466;
  color: #fff;
  padding: 4px 3px;
  min-width: 160px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  word-break: break-all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-tbody .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-tbody .div-tr:nth-child(2n+1) .div-td {
  background: #fff;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-tbody .div-tr:nth-child(2n) .div-td {
  background: #f9f9f9;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-tbody .div-tr:last-child .div-td {
  border-bottom: 1px solid #ddd;
}

.visit-status-intro-page .grouptwotablefixedhead .table-box-Schedule .div-tbody .div-tr .div-td {
  min-width: 160px;
  width: 100%;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 4px 3px;
}

.box-intro {
  margin: 0 auto;
  width: 58px;
  height: 38px;
  background-color: transparent;
}

.box-intro.box-blue {
  background-image: url(../../images/hexagon_remedy_500.png);
  background-repeat: no-repeat;
}

.box-intro.box-gray {
  background-image: url(../../images/hexagon_grey_500.png);
  background-repeat: no-repeat;
}

.box-intro.box-blue a {
  border: none;
  background-color: transparent;
  margin: auto;
}

.box-intro.box-gray a {
  border: none;
  background-color: transparent;
  margin: auto;
}
.box-intro .item-lst {
  margin-top: 3px;
}

.box-intro.box-gray-dashed {
  border: none;
  background-image: url(../../images/hexagon_dash_500.png);
  background-repeat: no-repeat;
}

.box-intro.box-gray-dashed a {
  border: none;
  background-color: transparent;
  margin: auto;
}

.box-intro.box-item-red a:after {
  position: absolute;
  top: 0;
  left: -14px;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  background: #DD0000;
}

.box-intro.box-item-yellow a:after {
  position: absolute;
  top: calc(50% - 3px);
  left: -14px;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  background: #FFCC00;
}

.box-intro.box-item-gray a:after {
  position: absolute;
  bottom: 0;
  left: -14px;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  background: #AAAAAA;
}

.box-intro a {
  width: 38px;
  height: 38px;
  display: block;
  background: #fff;
  position: relative;
}

.box-intro .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.box-intro .item img {
  max-height: 13px;
}

.box-intro .item-lst ul li {
  float: left;
  width: 50%;
  padding: 2px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-intro .item-lst ul li img {
  max-height: 13px;
}

.modal-visit .modal-header {
  background: #2a3f54;
  padding: 5px 15px;
  color: #fff;
  position: relative;
}

.modal-visit .modal-header .close {
  color: #fff;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 0;
}

.modal-visit .modal-header .close:hover {
  color: #fff;
  opacity: 1;
}

.modal-visit .lst-checkbox-visit {
  border: 1px #ccc solid;
  padding: 8px;
  max-height: 226px;
  overflow-y: auto;
}

.modal-visit .lst-checkbox-visit ul li {
  padding-bottom: 8px;
}

.modal-visit .lst-checkbox-visit ul li:last-child {
  padding-bottom: 0;
}

.modal-visit .btn-modal-visit .btn {
  min-width: 125px;
}

.subject-page .subject-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subject-page .subject-row.subject-row-fl .col-left {
  width: 150px;
}

.subject-page .subject-row.subject-row-fl .col-right {
  width: calc(100% - 150px);
}

.subject-page .subject-row .col-left {
  width: 240px;
  -webkit-transition: 200ms all;
  -o-transition: 200ms all;
  transition: 200ms all;
}

.subject-page .subject-row .col-right {
  width: calc(100% - 240px);
  -webkit-transition: 200ms all;
  -o-transition: 200ms all;
  transition: 200ms all;
}

.subject-page .subject-row .scroll-bar {
  height: calc(100vh - 135px);
  /* overflow-y: hidden; */
}

.subject-page .tbl-subject {
  padding-left: 8px;
}

.subject-page .tbl-subject tr:first-of-type th {
  background: #D9D9D9;
  font-weight: 600;
}

.subject-page .tbl-subject tr th,
  .subject-page .tbl-subject tr td {
  min-width: 40px;
}

.subject-page .tbl-subject tr th {
  background: #F2F2F2;
  font-weight: normal;
  text-align: left;
  min-width: 150px;
  max-width: 150px;
}

.subject-page .tbl-subject tr td:nth-child(2) {
  min-width: 450px;
  max-width: 450px;
}

.subject-page .tbl-subject tr td:nth-child(3) {
  text-align: center;
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.subject-page .comment-cmt {
  display: none;
}

.subject-page .item {
  /*padding-top: 6px;*/
  padding-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.subject-page .item .btn {
  padding: 0 4px;
  margin-left: 5px;
  height: 24px;
  font-size: 10px;
}

.subject-page .item .nav-drop li a {
  color: #707070;
  padding: 0 8px;
  display: block;
  font-size: 13px;
}

.subject-page .w-150 {
  width: 150px;
}

.subject-page .form-tl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subject-page .form-tl label {
  width: 50px;
}

.subject-page .form-tl .form-control {
  margin-right: 8px;
}

.subject-page .form-col ul {
  margin: 0 0 0 -16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.subject-page .form-col ul li {
  min-width: 260px;
  max-width: 260px;
  padding-left: 16px;
  margin: 5px 0;
}

.subject-page .form-col ul li:nth-of-type(2n+1) {
  clear: both;
}

.subject-page .form-col ul li:last-child {
  min-width: 100px;
  max-width: 100px;
}

.subject-page .form-col ul li:first-of-type {
  min-width: 260px;
  max-width: 260px;
}

.subject-page .form-radio .custom-control {
  margin-right: 16px;
}

.subject-page .form-radio .custom-control:last-child {
  margin-right: 0;
}

.item-chat {
  background: url(/images/icon-chat.png) no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
}

.item-remove-line {
  background: url(/images/ic_clear_24px.png) no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
}

.icon-help {
  background: url(/images/icon-help.png) no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
}

.icon-slideshow {
  background: url(../../images/ic_slideshow_24px.png) no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
}

.icon-collapse-record {
  background: url(../../images/ic_collapse_record.png) no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
}

.icon-expand-record {
  background: url(../../images/ic_expand_record.png) no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
}

.icon-close-record {
  background: url(../../images/ic_close_record.png) no-repeat;
  background-size: 12px;
  width: 12px;
  height: 12px;
}

.item-drop {
  width: 20px;
  height: 28px;
  position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 9;
}

.item-drop:after {
  position: absolute;
  top: calc(50% - 9px);
  left: 5px;
  font-family: 'FontAwesome';
  content: "\f104";
  color: #6A6A6A;
  font-size: 18px;
  font-weight: normal;
}

.item-drop.item-active:after {
  content: "\f105";
}

.collapse-nav {
  position: relative;
}

.collapse-nav > ul > li .accordion-click {
  background: #EDEDED;
  border-bottom: 1px #707070 solid;
  padding: 4px 30px 4px 26px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collapse-nav > ul > li .accordion-click:after {
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
  font-family: 'FontAwesome';
  content: "\f107";
  color: #6A6A6A;
  font-size: 18px;
  font-weight: normal;
}

.collapse-nav > ul > li .accordion-click.active:after {
  content: "\f106";
}

.collapse-nav .accordion-sub {
  display: none;
}

.collapse-nav .accordion-sub > ul > li {
  border-bottom: 1px #ccc dashed;
}

.collapse-nav .accordion-sub .accordion-click {
  background: none;
  border: none;
  padding: 4px 30px 4px 8px;
}

.collapse-nav .accordion-sub .accordion-sub {
  padding: 8px 0;
}

.collapse-nav .accordion-sub .accordion-sub ul li {
  position: relative;
  border: none;
        /*
					&:before {
						background: #707070;
						bottom: auto;
						content: "";
						height: 1px;
						left: 4px;
						position: absolute;
						right: auto;
						top: 50%;
						width: 8px;
						z-index: 1;
					}
					&:after {
						border-left: 1px solid #707070;
						bottom: 0;
						content: "";
						left: 4px;
						position: absolute;
						top: 0;
					}
					*/;
}

.collapse-nav .accordion-sub .accordion-sub ul li a {
  padding: 6px 26px 6px 16px;
  color: #333;
  display: block;
  position: relative;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collapse-nav .accordion-sub .accordion-sub ul li a:hover, .collapse-nav .accordion-sub .accordion-sub ul li a.active {
  color: #077CF8;
}

.collapse-nav .accordion-sub .accordion-sub ul li a.active {
  padding-left: 28px;
}

.collapse-nav .accordion-sub .accordion-sub ul li a.active:after {
  position: absolute;
  top: calc(50% - 9px);
  left: 16px;
  font-family: 'FontAwesome';
  color: #077CF8;
  font-size: 18px;
  font-weight: normal;
  content: "\f105";
}

.collapse-nav .box-item {
  position: absolute;
  right: 4px;
  top: 4px;
}

.collapse-nav .box-item span {
  position: relative;
  height: 25px;
  width: 38px;
  background: #fff;
  display: block;
}

.collapse-nav .box-item span:before {
  position: absolute;
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  left: -12px;
}

.collapse-nav .box-item.box-item-red span:before {
  background: #dd0000;
  top: 0;
}

.collapse-nav .box-item.box-item-yellow span:before {
  background: #ffcc00;
  top: calc(50% - 3px);
}

.collapse-nav .box-item.box-item-gray span:before {
  background: #aaaaaa;
  bottom: 0;
}

.collapse-nav .box-item.box-blue span {
  border: none;
  background-color: transparent;
  background-image: url(../../images/hexagon_remedy_402.png);
}

.collapse-nav .box-item.box-gray span {
  border: none;
  background-color: transparent;
  background-image: url(../../images/hexagon_grey_402.png);
}

.collapse-nav .box-item.box-gray-dashed span {
  border: none;
  background-color: transparent;
  background-image: url(../../images/hexagon_dash_402.png);
}

.collapse-nav .box-item.box-gray-dashed span:after {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 21px;
  width: 21px;
  border: none;
  content: "";
}

.fix-btn-footer .col-left {
  float: left;
}

.fix-btn-footer .col-right {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fix-btn-footer .col-right .dropdown-menu {
  padding: 0;
  min-width: 300px;
  max-width: 300px;
}

.fix-btn-footer .col-right .dropdown-menu .title {
  background: #26b99a;
  color: #fff;
  padding: 5px 15px;
  border-radius: 4px 4px 0 0;
}

.fix-btn-footer .col-right .dropdown-menu .title-fl {
  background: #FF9999;
  padding: 5px 15px;
  border-radius: 4px 4px 0 0;
}

.fix-btn-footer .col-right .dropdown-menu .btn-comfim .btn {
  min-width: 100px;
}

.cmt-comment .form-control {
  background: url(/images/icon-common.png) no-repeat 4px 50%;
  padding-left: 24px;
}

.item-subject ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.item-subject ul li {
  text-align: center;
  padding: 0 4px;
  width: 30px;
}

.item-subject ul li img {
  max-height: 12px;
}

.item-subject ul li .item-checkbox .custom-control {
  padding-left: 20px;
}

.tbl-table-subject {
  overflow-x: auto;
  padding-bottom: 20px;
}

.tbl-table-subject tr.please-check .please-txt {
  float: left;
  width: calc(100% - 204px);
}

.tbl-table-subject tr.please-check .please-txt .please-red {
  color: #F60A0A;
}

.tbl-table-subject tr.please-check .please-txt .please-yellow {
  color: #CC9F07;
}

.tbl-table-subject tr.please-check .please-btn {
  float: right;
  width: 204px;
  text-align: right;
}

.tbl-table-subject tr.please-check .please-btn .btn {
  padding: 0 8px;
}

.tbl-table-subject tr.please-check .please-btn .please-red {
  color: #f60a0a;
}

.tbl-table-subject tr.create th {
  background: #FFEAEA;
}

.tbl-table-subject tr.create .create-form {
  padding: 8px;
}

.tbl-table-subject tr.create .create-form .create-btn .btn {
  min-width: 125px;
}

.tbl-table-subject tr.create .tlt {
  border-bottom: 1px #707070 solid;
}

.tbl-table-subject tr.create .tlt h2 {
  color: #F60A0A;
  background: url(/images/icon-help.png) no-repeat 0 6px;
  padding: 0 0 0 20px;
  font-weight: 600;
  font-size: 16px;
}

.tbl-table-subject tr:nth-child(2) th {
  width: 90%;
  background: #d9d9d9;
  font-weight: 600;
  text-align: center;
}

.tbl-table-subject tr:nth-child(2) th span {
  font-size: 16px;
  vertical-align: middle;
}

.tbl-table-subject tr th {
  text-align: left;
  background: #f2f2f2;
  font-weight: normal;
  max-width: 160px;
  min-width: 160px;
}

.tbl-table-subject tr td:nth-child(1) {
  min-width: 130px;
  max-width: 130px;
}

.tbl-table-subject tr td:nth-child(3) {
  max-width: 400px;
  min-width: 400px;
}

.tbl-table-subject tr td:nth-child(4) {
  text-align: center;
  min-width: 102px;
  max-width: 102px;
  width: 8%;
}

.btn-correction .btn {
  font-size: 10px;
  height: 24px;
  padding: 0 4px;
  margin-top: 4px;
}

.tbl-table-subject-fl {
  overflow-x: auto;
  padding-bottom: 20px;
}

.tbl-table-subject-fl tr.please-check td {
  border-bottom: none;
}

.tbl-table-subject-fl tr.please-check .please-txt {
  float: left;
  width: calc(100% - 280px);
}

.tbl-table-subject-fl tr.please-check .please-txt .please-red {
  color: #F60A0A;
}

.tbl-table-subject-fl tr.please-check .please-txt .please-yellow {
  color: #CC9F07;
}

.tbl-table-subject-fl tr.please-check .please-btn {
  float: right;
  width: 280px;
  text-align: right;
}

.tbl-table-subject-fl tr.please-check .please-btn .btn {
  padding: 0 8px;
}

.tbl-table-subject-fl tr.please-check .please-btn .please-red {
  color: #F60A0A;
}

.tbl-table-subject-fl tr.create.create-fl th {
  background: #F2EBD5;
}

.tbl-table-subject-fl tr.create.create-fl .tlt.title h2 {
  color: #000;
}

.tbl-table-subject-fl tr.create.create-fl .tlt h2 {
  color: #CC9F07;
}

.tbl-table-subject-fl tr.create td {
  border-bottom: none;
  border-top: none;
}

.tbl-table-subject-fl tr.create th {
  background: #FFEAEA;
}

.tbl-table-subject-fl tr.create .create-form {
  padding: 8px;
}

.tbl-table-subject-fl tr.create .create-form .create-btn .btn {
  min-width: 125px;
}

.tbl-table-subject-fl tr.create .tlt {
  border-bottom: 1px #707070 solid;
}

.tbl-table-subject-fl tr.create .tlt h2 {
  color: #F60A0A;
  font-weight: 600;
  font-size: 16px;
}

.tbl-table-subject-fl tr.tr-add td {
  border-bottom: none;
  border-top: none;
}

.tbl-table-subject-fl tr.tr-add th {
  border-top: 2px #707070 solid;
  background: rgba(115, 139, 162, 0.3);
}

.tbl-table-subject-fl tr:nth-child(2) th {
  width: 90%;
  background: #d9d9d9;
  font-weight: 600;
  text-align: center;
}

.tbl-table-subject-fl tr:nth-child(2) th span {
  font-size: 16px;
  vertical-align: middle;
}

.tbl-table-subject-fl tr:nth-child(3) th {
  text-align: center;
}

.tbl-table-subject-fl tr th {
  background: #f2f2f2;
  font-weight: normal;
  text-align: left;
}

.tbl-table-subject-fl tr td {
  text-align: center;
  min-width: 130px;
  max-width: 130px;
}

.tbl-table-subject-fl tr td:nth-child(1) {
  min-width: 130px;
  max-width: 130px;
  text-align: left;
}

.tbl-table-subject-fl tr td:nth-child(2) {
  min-width: 40px;
  max-width: 40px;
}

.tbl-table-subject-fl tr td:nth-child(3), .tbl-table-subject-fl tr td:nth-child(4), .tbl-table-subject-fl tr td:nth-child(5), .tbl-table-subject-fl tr td:nth-child(6) {
  vertical-align: top;
}

.tbl-table-subject-fl tr td:nth-child(7) {
  min-width: 102px;
  max-width: 102px;
  width: 8%;
}

.item-add {
  background: url(/images/group-1438-3.png) no-repeat;
  background-size: 21px;
  display: block;
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin: 0 auto;
}

.tbl-modal-subject tr th {
  min-width: 300px;
  max-width: 300px;
  background: #26B99A;
  font-weight: normal;
  text-align: left;
  color: #fff;
}

.tbl-modal-subject tr td {
  width: 85%;
}

.tbl-modal-subject-fl {
  max-height: 400px;
  overflow-x: auto;
  display: none;
}

.tbl-modal-subject-fl tr th {
  min-width: 300px;
  max-width: 300px;
  font-weight: normal;
  text-align: left;
}

.tbl-modal-subject-fl tr td {
  width: 85%;
}

.tbl-modal-subject-fl .subject-textarea {
  padding: 8px 0 0;
}

.lst-radio {
  display: none;
  padding: 0 0 0 305px;
}

.lst-radio .subject-textarea {
  padding: 8px 0 0;
}

/**** update ****/
.page-training .box-tablecodelist .box-tbodytable {
  max-height: calc(100vh - 254px);
}

.page-training .box-tablecodelist .table tr th,
.page-training .box-tablecodelist .table tr td {
  min-width: 120px;
  max-width: 120px;
}

.page-training .box-tablecodelist .table tr th:nth-child(1),
  .page-training .box-tablecodelist .table tr td:nth-child(1) {
  min-width: 300px;
  max-width: 300px;
}

/** modal ***/
.modal-group .modal-content .modal-header {
  position: relative;
  padding: 5px 15px;
  background: #445466;
}

.modal-group .modal-content .modal-header .modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-group .modal-content .modal-header .close {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
  color: #fff;
  opacity: 1;
}

.modal-group .modal-content .modal-body {
  padding: 10px 15px;
}

.modal-group .btn-confirm .btn {
  min-width: 125px;
}

/*****/
.group-subject {
  padding-bottom: 20px;
}

.group-subject .pd-subject {
  padding: 4px;
}

.group-subject .subject-thead-th,
  .group-subject .subject-cmt,
  .group-subject .subject-danger {
  border: 1px solid #ddd;
  border-top: none;
}

.group-subject .please-txt {
  float: left;
  width: auto;
}

.group-subject .please-txt .please-red {
  color: #f60a0a;
}

.group-subject .please-txt .please-yellow {
  color: #cc9f07;
}

.group-subject .please-btn {
  float: right;
  text-align: right;
  width: 280px;
}

.group-subject .please-btn .btn {
  padding: 0 8px;
}

.group-subject .please-btn .please-red {
  color: #f60a0a;
}

.group-subject .create-form {
  padding: 0 4px;
}

.group-subject .create-form.create-form-fl .tlt h2 {
  background: none;
  padding-left: 0;
}

.group-subject .create-form .create-btn .btn {
  min-width: 125px;
}

.group-subject .create-form .tlt {
  border-bottom: 1px #707070 solid;
}

.group-subject .create-form .tlt h2 {
  color: #F60A0A;
  background: url(/images/icon-help.png) no-repeat 0 1px;
  padding: 0 0 0 20px;
  font-weight: 600;
  font-size: 12px;
}

.subject-page .group-subject-fl .div-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subject-page .group-subject-fl .div-flex .div-td {
  min-width: 350px;
  width: calc(100% - 262px);
  min-height: 45px;
  border-right: 1px #ddd solid;
}

.subject-page .group-subject-fl .div-flex .div-td:first-of-type {
  min-width: 160px;
  width: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  word-break: break-all;
}

.subject-page .group-subject-fl .div-tr .div-td {
  padding: 4px;
}

.subject-page .group-subject-fl01 .div-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subject-page .group-subject-fl01 .div-flex .div-td {
    /*
				max-width: 400px;
	    	min-width: 160px;
	    	*/
  width: 100%;
  border-right: 1px #ddd solid;
}

.subject-page .group-subject-fl01 .div-flex .div-td:first-of-type {
  min-width: 70px;
  width: 70px;
}

.subject-page .group-subject-fl01 .div-flex .div-td:first-of-type .label-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: calc(100% - 24px);
}

.subject-page .group-subject-fl01 .div-flex .div-td:last-child .label-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: calc(100% - 24px);
}

.subject-page .group-subject-fl01 .box-tbodytbl {
  overflow-x: auto;
  overflow-y: hidden;
}

.subject-page .group-subject-fl01 .boxtableleft .div-td {
  padding: 4px;
}

.subject-page .group-subject-fl02 .div-tr {
  border-left: 1px solid #ddd;
}

.subject-page .group-subject-fl02 .thead-tlt {
  display: block;
  padding: 8px 4px;
}

.subject-page .group-subject-fl02 .div-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subject-page .group-subject-fl02 .div-flex .div-td {
  width: calc(100% - 290px);
  border-left: 1px solid #ddd;
  padding: 4px;
}

.subject-page .group-subject-fl02 .div-flex .div-td:first-of-type {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 250px;
  min-width: 250px;
  border-left: none;
}

.subject-page .group-subject-fl02 .div-flex .div-td:last-child {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 40px;
  min-width: 40px;
  text-align: center;
}

.subject-page .group-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subject-page .group-table.group-subject-fl02 {
  display: block;
  padding: 16px 0 0 8px;
}

.subject-page .group-table.group-subject-fl02 .boxtablescroll {
  width: auto;
}

.subject-page .group-table .boxtableleft {
  max-width: 130px;
  min-width: 130px;
}

.subject-page .group-table .boxtableleft .box-tbodytable01 {
  position: relative;
  z-index: 1;
}

.subject-page .group-table .boxtablescroll {
  width: calc(100% - 130px);
}

.subject-page .group-table .boxtablescroll .box-tbodytable {
  overflow-x: auto;
  position: relative;
  z-index: 2;
  background: #fff;
}

.subject-page .div-tr {
  border-right: 1px #ddd solid;
  border-bottom: 1px #ddd solid;
}

.subject-page .thead-tlt {
  background: #d9d9d9;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.subject-page .thead-tlt span {
  font-size: 16px;
}

.subject-page .div-box-gray {
  background: #f2f2f2;
}

.subject-page .div-box-red {
  background: #ffeaea;
}

.subject-page .div-box-yellow {
  background: #f2ebd5;
}

.subject-page .add-from {
  background: rgba(115, 139, 162, 0.3);
  border-top: 2px solid #707070;
}

.subject-page .subject-error {
  border-top: 1px #ddd solid;
  padding: 4px;
  overflow: hidden;
}

.subject-page .cmt-comment {
  border-top: 1px #ddd solid;
  padding: 4px;
  overflow: auto;
}

.subject-page .create-gray .tlt h2 {
  background: none;
  padding-left: 0;
  color: #000;
}

.subject-page .create-yellow .tlt h2 {
  background: none;
  padding-left: 0;
  color: #cc9f07;
}

.subject-page .add-label {
  background: rgba(115, 139, 162, 0.3);
  border-top: 2px solid #707070;
  padding: 4px;
  width: 100%;
}

.subject-page .label-txt {
  border-bottom: 1px #ddd solid;
  padding: 4px;
  font-size: 12px;
}

.subject-page .box-tbodytbl .tablescroll {
  /*float: left;*/
  min-width: 100%;
}

.subject-page .div-content .item-subject {
  text-align: center;
}

.subject-page .label-content {
  padding: 4px;
}

.subject-page .box-tbodytbl {
  overflow: hidden;
}

.d-flex-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.d-flex-form .form-label {
  margin-right: 16px;
  margin: 4px 16px 4px 0;
}

.d-flex-form .form-label.form-label-vertical .custom-control {
  display: block;
}

.d-flex-form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.d-flex-form .form-center .custom-control {
  margin: 4px 12px 4px 0;
}

.d-flex-form .form-center .custom-control:last-child {
  margin-right: 0;
}

.page-download .tbl-table.tbl-hg .scroll-bar {
  height: calc(100vh - 250px);
}

.page-download .tbl-table.tbl-hg-fl .scroll-bar {
  height: calc(100vh - 330px);
}

.page-download .tbl-table tr td {
  vertical-align: top;
  padding: 0 0 0 4px;
  width: 25%;
}

.page-download .tbl-table tr td .scroll-bar {
  padding: 10px 0 0;
  overflow-y: auto;
}

.page-download .tbl-table tr td .scroll-bar ul li {
  padding: 5px 0;
}

.page-download .tbl-table tr td .scroll-bar ul li .custom-control.custom-checkbox:first-child {
  margin-right: 20px;
}

.page-download02 .tbl-table tr td {
  vertical-align: top;
  padding: 8px 0;
}

.Dataset {
  padding: 0 16px 16px;
}

.box-tbodytable .Dataset_History {
  max-height: 620px;
}

.custom-control-title.Dataset_History {
  margin-right: 0;
}

.box-tbodytable.Dataset_History {
  max-height: calc(100vh - 254px);
  overflow: auto;
}

.table.table.table-box.Dataset_History tbody tr td {
  float: inherit;
  padding: 4px;
}

.custom-control-title.Dataset_History {
  margin-right: 0;
}

.custom-control-title.Dataset_History.mt-16 {
  margin-right: 0;
}

table.table.table-box.Dataset_History thead tr th:nth-child(1) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History thead tr th:nth-child(2) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Dataset_History thead tr th:nth-child(3) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History thead tr th:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History thead tr th:nth-child(5) {
  min-width: 260px;
  max-width: 260px;
  width: 28%;
}

table.table.table-box.Dataset_History thead tr th:nth-child(6) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(1) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(2) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(3) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(5) {
  min-width: 260px;
  max-width: 260px;
  width: 28%;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(5) .text-link {
  text-decoration: underline;
  cursor: pointer;
}

table.table.table-box.Dataset_History tbody tr td:nth-child(6) {
  min-width: 130px;
  max-width: 130px;
  width: 15%;
}

.Subject-PDF {
  padding: 16px;
}

.page-download .custom-control-title {
  color: #738BA2;
}

.page-download label.custom-control.custom-radio1 {
  margin-left: 40px;
}

.page-download label.custom-control.custom-checkbox1 {
  margin-left: 40px;
}

.page-download .tbl-table tr td {
  padding: 0 0 0 4px;
  width: 20%;
}

.page-download .text-left {
  float: left;
}

.box-tbodytable.box-tbodytable.tableSubject-PDF {
  max-height: calc(100vh - 254px);
  overflow: auto;
}

.table.table.table-box.Subject-PDF tbody tr td {
  float: inherit;
  padding: 4px;
}

.custom-control-title.Subject-PDF {
  margin-right: 0;
  padding: 16px;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(1) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(2) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(3) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(4) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(5) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(6) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(7) {
  min-width: 210px;
  max-width: 210px;
  width: 23%;
}

table.table.table-box.Subject-PDF thead tr th:nth-child(8) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(1) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(2) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(3) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(4) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(5) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(6) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(7) {
  min-width: 210px;
  max-width: 210px;
  width: 23%;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(7) .text-link {
  text-decoration: underline;
  cursor: pointer;
}

table.table.table-box.Subject-PDF tbody tr td:nth-child(8) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

.eCRF {
  padding: 16px;
}

.wrap.page-eCRF .tbl-table {
  border: 0px solid #707070;
}

.wrap.page-eCRF .tbl-table tr td {
  vertical-align: top;
  padding: 4px;
}

.wrap.page-eCRF .tbl-table tr .sub {
  background: #F5F5F5;
  width: 25%;
}

.wrap.page-eCRF .form-control {
  width: 35%;
  padding: 0;
}

.wrap .user-info {
  padding: 16px;
}

.wrap .user-info .box-form {
  padding: 0px;
  margin-bottom: 20px;
}

.wrap .user-info .box-form .title-tlt h2 {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  padding-left: 25px;
  color: #606060;
}

.wrap .user-info .box-form .title-tlt h2:after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  width: 16px;
  height: 16px;
  content: "";
  background: #707070;
}

.wrap .user-info .info {
  padding: 0 35px;
}

.wrap .user-info .info .form-forgot-password {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wrap .user-info .info .form-forgot-password .form-left {
  width: 250px;
  padding-top: 8px;
}

.wrap .user-info .info .form-forgot-password .form-left label {
  font-weight: 600;
  color: #738BA2;
}

.wrap .user-info .info .form-forgot-password .form-right {
  width: calc(800px - 230px);
  padding-left: 24px;
}

.wrap .user-info .info .form-forgot-password .form-right-middle {
  width: 400px;
  padding-left: 24px;
}

.wrap .user-info .info .form-forgot-password .form-right-bottom {
  width: 200px;
  padding-left: 24px;
}

.wrap .user-info .info .btn-info .btn {
  min-width: 125px;
}

.modal-dialog-centered.lg {
  max-width: 800px;
  margin: 0 auto;
}

.btn-green-lg {
  width: 109px;
}

.modal-body.lg-top-middle.mb-16 {
  padding: 1rem 1rem 0;
}

.modal-content.lg {
  margin: 0 20px;
}

.modal-content.lg .lg-top .pull-left.lg {
  background: #2A3F54;
  min-width: 250px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  border-bottom-right-radius: 10px;
}

.modal-content.lg .lg-top .pull-right.lg {
  margin: 15px 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.modal-content.lg .lg-top-middle .form-lg .left {
  min-width: 150px;
}

.modal-content.lg .lg-top-middle .form-lg .right {
  min-width: 250px;
}

.modal-content.lg .lg-top-middle .form-lg .right-st {
  min-width: 577px;
}

.modal-content.lg .box-lg {
  margin: 0 1rem 1rem;
}

.modal-content.lg .box-lg th.title-th {
  background: #445466;
  width: 70%;
  max-width: 25px;
  min-width: 25px;
  color: #fff;
}

.modal-content.lg .box-lg th.title-th:first-child {
  width: 30%;
}

.modal-content.lg .box-lg td.text-left.text-lg {
  width: 30%;
}

.modal-content.lg .box-lg td.text-left.text-lg .text-link {
  text-decoration: underline;
  cursor: pointer;
}

.fix-bottom.user-info {
  left: 0;
  width: 100%;
}

table.table.table-box.user-info thead tr th:nth-child(1) {
  min-width: 400px;
  max-width: 400px;
  width: 45%;
}

table.table.table-box.user-info thead tr th:nth-child(2) {
  min-width: 400px;
  max-width: 400px;
  width: 45%;
}

table.table.table-box.user-info thead tr th:nth-child(3) {
  min-width: 80px;
  max-width: 80px;
  width: 10%;
}

table.table.table-box.user-info tbody tr td:nth-child(1) {
  min-width: 400px;
  max-width: 400px;
  width: 45%;
  padding: 8px 15px;
}

table.table.table-box.user-info tbody tr td:nth-child(2) {
  min-width: 400px;
  max-width: 400px;
  width: 45%;
  padding: 8px 15px;
}

table.table.table-box.user-info tbody tr td:nth-child(2) ul li {
  float: left;
  width: 20%;
  text-align: center;
}

table.table.table-box.user-info tbody tr td:nth-child(2) ul li:first-child {
  width: 80%;
}

table.table.table-box.user-info tbody tr td:nth-child(2) ul li .form-right-bottom,
    table.table.table-box.user-info tbody tr td:nth-child(2) ul li .frm-checkbox {
  margin-top: 5px;
}

table.table.table-box.user-info tbody tr td:nth-child(2) .custom-delete {
  cursor: pointer;
}

table.table.table-box.user-info tbody tr td:nth-child(3) {
  min-width: 80px;
  max-width: 80px;
  width: 10%;
}

table.table.table-box.user-info tbody tr td:nth-child(3) .custom-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-delete {
  cursor: pointer;
}

td.add {
  text-align: center;
  background: #CCD5DD;
}

.form-left-site {
  width: 180px;
}

.wrap .user-info-site .info {
  padding: 0;
}

.form-left-r {
  width: 100px;
  margin-left: 80px;
}

.box-tbodytable.list-exams {
  max-height: calc(100vh - 254px);
}

.frm-grpbtn.list-exams {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

ul.list-exams li {
  padding: 2px 0;
}

.colflex.flexinput-6.Site-management {
  width: 70%;
}

.colflex.flexinput-6.Site-management:first-child {
  width: 30%;
}

.txt-label.Site-management {
  min-width: 80px;
}

input.form-control.form-control-date.Site-management {
  margin-left: 10px;
  max-width: 190px;
}

input.form-control.form-control-date.Site-management:first-child {
  margin-right: 10px;
  margin-left: 0;
}

.frm-input.Site-management {
  min-width: 200px;
}

.frm-input.Site-management {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

button.btn.btn-green.Site-management {
  min-width: 109px;
}

.frm-grpbtn.Site.management {
  margin-bottom: 10px;
}

.page-site-management .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 280px);
  /*max-height: calc(100vh - 254px);*/
}

.page-site-management .tablefixedhead tr th,
.page-site-management .tablefixedhead tr td {
  min-width: 100px;
  max-width: 100px;
}

.page-site-management .tablefixedhead tr th:nth-child(1),
  .page-site-management .tablefixedhead tr td:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.page-site-management .tablefixedhead tr th:nth-child(2),
  .page-site-management .tablefixedhead tr td:nth-child(2) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.page-site-management .tablefixedhead tr th:nth-child(3), .page-site-management .tablefixedhead tr th:nth-child(4),
  .page-site-management .tablefixedhead tr td:nth-child(3),
  .page-site-management .tablefixedhead tr td:nth-child(4) {
  min-width: 180px;
  max-width: 180px;
  width: 18%;
}

.page-site-management .tablefixedhead tr th:nth-child(5),
  .page-site-management .tablefixedhead tr td:nth-child(5) {
  min-width: 150px;
  max-width: 150px;
  width: 16%;
}

.page-site-management .tablefixedhead tr th:nth-child(6),
  .page-site-management .tablefixedhead tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 8%;
}

.page-site-management .tablefixedhead tr th:nth-child(7),
  .page-site-management .tablefixedhead tr td:nth-child(7) {
  min-width: 150px;
  max-width: 150px;
  width: 18%;
}

.page-site-management .tablefixedhead tr td:nth-child(1), .page-site-management .tablefixedhead tr td:nth-child(6), .page-site-management .tablefixedhead tr td:nth-child(7) {
  text-align: center;
}

.fix-bottom.Site .text-left {
  float: left;
}

.modal-header.Site {
  background: #26B99A;
  color: #fff;
  padding: 5px 15px;
}

.modal-footer.Site {
  border: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.txt-label.User.management {
  width: 70px;
  margin-right: 10px;
}

.frm-input.management {
  width: 200px;
}

.frm-input.User-management {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

input.form-control.form-control-date.mr-8.two {
  width: 200px;
}

input.form-control.form-control-date.ml-8.two {
  width: 200px;
}

.box-tbodytable.management-table-scroll {
  max-height: calc(100vh - 244px);
}

/* .page-lst-subjects .management-table-scroll tr td {
  text-align: center;
} */

/* .page-lst-subjects .management-table-scroll tr td:nth-child(7) {
  text-align: left;
} */

.box-tbodytable.table-scroll {
  max-height: 270px;
}

.bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.frm-input.User.management {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Assignment {
  display: table;
  width: 100%;
}

.Assignment .roll {
  display: table-cell;
  text-align: center;
  width: 33.33333333%;
}

button.btn.btn-green.User.management {
  min-width: 109px;
}

.frm-grpbtn.User.management {
  margin-bottom: 10px;
}

.page-user-management .management-table .box-tbodytable {
  max-height: calc(100vh - 254px);
}

.page-user-management .management-table tr th,
.page-user-management .management-table tr td {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
  text-align: center;
}

.page-user-management .management-table tr th.text-left,
.page-user-management .management-table tr td.text-left {
	text-align: left;
}
.page-user-management .management-table tr th.w100,
.page-user-management .management-table tr td.w100 {
	width: 100px !important;
}

.page-user-management .management-table tr th.w150,
.page-user-management .management-table tr td.w150 {
	width: 160px !important;
	max-width: 160px !important;
	min-width: 160px !important;
}

.page-user-management .management-table tr th:nth-child(1), .page-user-management .management-table tr th:nth-child(11),
  .page-user-management .management-table tr td:nth-child(1),
  .page-user-management .management-table tr td:nth-child(11) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.page-user-management .management-table tr th:nth-child(2), .page-user-management .management-table tr th:nth-child(6),
  .page-user-management .management-table tr td:nth-child(2),
  .page-user-management .management-table tr td:nth-child(6) {
  min-width: 150px;
  max-width: 150px;
  width: 16%;
}

.page-user-management .management-table tr td:nth-child(2), .page-user-management .management-table tr td:nth-child(8), .page-user-management .management-table tr td:nth-child(10) {
  text-align: left;
}

.page-user-management .management-table tr td .drop-down {
  position: relative;
}

.page-user-management .management-table tr td .drop-down .dropdown-menu-right {
  margin: 0;
  padding: 0;
}

.page-user-management .management-table tr td .drop-down .dropdown-menu-right ul li a {
  color: #73879C;
  padding: 4px 8px;
  display: block;
}

.fix-bottom.user .text-left {
  float: left;
}

.modal-header.user {
  background: #26B99A;
  color: #fff;
  padding: 5px 15px;
}

.modal-footer.user {
  border: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

button.btn.btn-green.mr-8.btn.btn-secondary.text-center {
  background-color: #F5F5F5;
  color: #000;
  min-width: 115px;
}

button.btn.btn-green.mr-8.btn.btn-primary.text-center {
  background-color: #F5F5F5;
  color: #000;
  min-width: 115px;
}

.box-listEsc {
  padding: 16px;
}

.box-listEsc .box-groupformlistesc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.box-listEsc .box-groupformlistesc .colflex {
  padding: 8px 16px;
}

.box-listEsc .box-groupformlistesc .colflex .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
}

.box-listEsc .box-groupformlistesc .colflex .grpform-input .frm-input {
  width: 250px;
}

.box-listEsc .box-groupformlistesc .colflex .frm-grpbtn .btn {
  min-width: 110px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-2 .grpform-input .txt-label {
  width: 40px;
  margin-right: 10px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-2 .grpform-input .frm-input {
  width: 200px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-3 .grpform-input .frm-input {
  width: 100px;
}

.box-listEsc .box-tablelistesc .grpform-input {
  margin-bottom: 8px;
}

.box-listEsc .box-tablelistesc .grpform-input .form-control {
  width: 100px;
}

.box-listEsc .box-tablelistesc .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.heading-1 {
  font-size: 18px;
  font-weight: bold;
  color: #73879C;
}

.lhcheckbox {
  line-height: 31px;
}

.lh {
  line-height: 30px;
}

.heading-normal {
  font-size: 16px;
  color: #73879C;
}

.scroll-table {
  overflow: auto;
}

.grpform-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.grpform-input .txt-label {
  line-height: 30px;
}

.grpform-input .txt-required {
  line-height: 30px;
  width: 50px;
  margin-right: 10px;
}

.grpform-input .form-control {
  border-radius: 3px;
}

.grpform-input .form-control.w120px {
  width: 120px;
}

.grpform-input .form-control.w200px {
  width: 200px;
}

.grpform-input .grpform-radio {
  line-height: 30px;
}

.grpform-input-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
}

.grpform-input-fl .txt-unit {
  line-height: 30px;
  padding-left: 10px;
}

.grpform-input-fl .form-control {
  width: 100px;
}

.text-red {
  color: #FF0000;
}

.textlong-2 {
  height: 227px !important;
}

textarea.form-control {
  white-space: pre-wrap;
  word-break: break-all;
}

.txt-peduce {
  color: #fff;
  padding-top: 1px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.txt-peduce.txt-violet {
  background: #9054D6;
}

.txt-peduce.txt-blue {
  background: #006CFF;
}

.txt-peduce.txt-cyan {
  background: #0AA0BA;
}

.txt-peduce.txt-green {
  background: #1ABC9C;
}

.dropdown.noarrow-dropdown .dropdown-toggle:after {
  display: none;
}

.dropdown.noarrow-dropdown .dropdown-menu {
  width: 100%;
  margin: 0;
}

.textnotemore {
  position: absolute;
  bottom: 130px;
  left: 264px;
  padding: 10px 0 0 32px;
}

.icon-editor {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.icon-editor.icon-plus {
  background: url("/images/icon_play.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-file {
  background: url("/images/icon_file.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-change {
  background: url("/images/icon_change.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-play {
  background: url("/images/icon_add.png") no-repeat center;
}

.icon-editor.icon-vilot {
  background-color: #9054D6;
}

.icon-editor.icon-blue {
  background-color: #006CFF;
}

.icon-editor.icon-cyan {
  background-color: #0AA0BA;
}

.icon-editor.icon-green {
  background-color: #1ABC9C;
}

.text-link {
  color: #077CF8;
  text-decoration: underline;
  cursor: pointer;
}

.icon-plusmini {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
}

.icon-upto {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.icon-upto.disable {
  background: #C3C3C3;
}

.icon-downbottom {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.icon-downbottom.disable {
  background: #C3C3C3;
}

.icon-searchgreen {
  background: url("/images/icon_searchgreen.png") no-repeat center;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.icon-copytbl {
  background: url("/images/icon_copy.png") no-repeat center;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.grpagetion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grpagetion .form-control {
  width: 110px;
  margin-right: 8px;
}

.grpagetion .rangenumberpage {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grpagetion .page {
  width: 65%;
  text-align: right;
  padding-left: 10px;
}

.modal-preview .modal-content .modal-header, .modal-HistoryofAudit .modal-content .modal-header, .modal-HistoryofCodeList .modal-content .modal-header {
  position: relative;
  padding: 5px 15px;
}

.modal-preview .modal-content .modal-header .modal-title, .modal-HistoryofAudit .modal-content .modal-header .modal-title, .modal-HistoryofCodeList .modal-content .modal-header .modal-title {
  font-size: 18px;
  font-weight: bold;
}

.modal-preview .modal-content .modal-header .close, .modal-HistoryofAudit .modal-content .modal-header .close, .modal-HistoryofCodeList .modal-content .modal-header .close {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
}

.modal-preview .modal-content .modal-body, .modal-HistoryofAudit .modal-content .modal-body, .modal-HistoryofCodeList .modal-content .modal-body {
  padding: 10px 15px;
}

.modal-preview .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryofAudit .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryofCodeList .modal-content .modal-body .grp-buttonfooter .btn {
  min-width: 110px;
}

.modal-preview .modal-content .modal-body .groupflex-md, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-preview .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md .flexleft {
  width: 30%;
}

.modal-preview .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md .flexright {
  width: 70%;
}

.modal-preview .modal-dialog {
  max-width: 900px;
}

.modal-preview .modal-content .modal-body {
  padding-bottom: 16px;
}

.modal-HistoryofAudit .modal-dialog, .modal-HistoryofCodeList .modal-dialog {
  max-width: 1400px;
}

.modal-HistoryofAudit .modal-content .modal-body, .modal-HistoryofCodeList .modal-content .modal-body {
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input {
  margin-bottom: 8px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control {
  width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.modal-HistoryofCodeList .modal-dialog {
  max-width: 1000px;
}

.box-CodeList {
  padding: 16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex {
  padding: 0 16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex .frm-grpbtn .btn {
  min-width: 110px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-1 .grpform-input .txt-label {
  width: 30px;
  margin-right: 10px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-1 .grpform-input .frm-input {
  width: 200px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-2 .grpform-input .txt-label {
  width: 46px;
  margin-right: 10px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-2 .grpform-input .frm-input {
  width: 200px;
}

.box-CodeList .box-tablecodelist .grpform-input {
  margin-bottom: 8px;
}

.box-CodeList .box-tablecodelist .grpform-input .form-control {
  width: 100px;
}

.box-CodeList .box-tablecodelist .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.e-training-slider {
  padding: 16px;
}

.txt-label.e-training {
  min-width: 80px;
}

.colflex.flexinput-4.e-training {
  width: 33.333333%;
}

.w3-display-right {
  position: absolute;
  top: 50%;
  right: -10%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}

.box-form-e {
  border: 2px solid #777777;
}

.training-content {
  max-width: 850px;
  margin: 50px auto;
  padding: 0 30px;
  position: relative;
}

.e-training-left {
  color: #707070;
  font-size: 70px;
  background: inherit;
  position: absolute;
  top: 50%;
  left: -5%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.e-training-left:hover {
  color: #DDDDDD;
}

.e-training-right {
  color: #707070;
  font-size: 70px;
  background: inherit;
  position: absolute;
  top: 50%;
  right: -6%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.e-training-right:hover {
  color: #DDDDDD;
}

input.form-control.form-control-date.e-training {
  margin-left: 10px;
  max-width: 190px;
}

input.form-control.form-control-date.e-training:first-child {
  margin-right: 10px;
  margin-left: 0;
}

.frm-input.e-training {
  width: 200px;
}

.frm-input.e-training .form-control-search {
  width: 200px;
}

.frm-input.e-training {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

button.btn.btn-green.e-training {
  min-width: 109px;
}

.frm-grpbtn.e-training {
  margin-bottom: 10px;
}

.box-tbodytable.table-e-training {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

table.table.table-box.e-training thead tr th:nth-child(1) {
  min-width: 280px;
  max-width: 280px;
  width: 30%;
  padding: 4px;
}

table.table.table-box.e-training thead tr th:nth-child(2) {
  min-width: 80px;
  max-width: 80px;
  width: 10%;
  padding: 4px;
}

table.table.table-box.e-training thead tr th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
}

table.table.table-box.e-training thead tr th:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.e-training thead tr th:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.e-training thead tr th:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.e-training thead tr th:nth-child(7) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(1) {
  min-width: 280px;
  max-width: 280px;
  width: 30%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(2) {
  min-width: 80px;
  max-width: 80px;
  width: 10%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.e-training tbody tr td:nth-child(7) {
  min-width: 100px;
  max-width: 100px;
  width: 12%;
  padding: 4px;
}

.btn-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

li.custom-control.custom-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-radio {
  margin-bottom: 5px;
}

.list-subj {
  margin-top: 4px;
}

.modal-content.list-sb {
  margin: 0;
}

.txt-label.User.management {
  width: 70px;
  margin-right: 10px;
}

.frm-input.management {
  width: 200px;
}

.frm-input.User-management {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

input.form-control.form-control-date.mr-8.two {
  width: 200px;
}

input.form-control.form-control-date.ml-8.two {
  width: 200px;
}

.box-tbodytable.table-scroll {
  max-height: 270px;
}

.bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.frm-input.User.management {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

button.btn.btn-green.User.management {
  min-width: 109px;
}

.frm-grpbtn.User.management {
  margin-bottom: 10px;
}

table.table.table-box.List-subj thead tr th:nth-child(1) {
  min-width: 25px;
  max-width: 25px;
  width: 3%;
}

table.table.table-box.List-subj thead tr th:nth-child(2) {
  min-width: 120px;
  max-width: 120px;
  width: 13%;
  padding: 4px;
}

table.table.table-box.List-subj thead tr th:nth-child(3) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
  padding: 4px;
}

table.table.table-box.List-subj thead tr th:nth-child(4) {
  min-width: 120px;
  max-width: 120px;
  width: 13%;
  padding: 4px;
}

table.table.table-box.List-subj thead tr th:nth-child(5) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
  padding: 4px;
}

table.table.table-box.List-subj thead tr th:nth-child(6) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
  padding: 4px;
}

table.table.table-box.List-subj thead tr th:nth-child(7) {
  min-width: 190px;
  max-width: 190px;
  width: 20%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(1) {
  min-width: 25px;
  max-width: 25px;
  width: 3%;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(2) {
  min-width: 120px;
  max-width: 120px;
  width: 13%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(3) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(4) {
  min-width: 120px;
  max-width: 120px;
  width: 13%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(5) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(6) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
  padding: 4px;
}

table.table.table-box.table-box.table-striped.List-subj tbody tr td:nth-child(7) {
  min-width: 190px;
  max-width: 190px;
  width: 20%;
  padding: 4px;
}

.btn-orange {
  background-color: #FD8A02;
  border: 1px #FD8A02 solid;
  color: #fff;
}

.btn-orange-st {
  background-color: #F5F5F5;
  border: 1px #FD8A02 solid;
  color: #000;
}

.fix-bottom.list .text-left {
  float: left;
}

.dropbtn {
  cursor: pointer;
  position: relative;
}

.dropbtn:after {
  position: absolute;
  top: 1px;
  right: 0;
  font-family: 'FontAwesome';
  content: "\f0d7";
  color: #fff;
  font-size: 16px;
}

.dropdown-content-list {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: -250px;
}

.dropdown-content a {
  color: black;
  padding: 4px;
  text-decoration: none;
  display: block;
}

.show {
  display: block;
}

.list-sb-title {
  background: #FD8A02;
  width: 100%;
  padding: 10px;
  color: #fff;
  text-align: left;
}

.list-scroll {
  padding: 0 10px 10px;
}

.table-scroll-list.mb-8.mt-8 {
  overflow-y: scroll;
  height: 111px;
  border: 1px solid #707070;
  padding: 8px;
}

.text-throught {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: red !important;
  text-decoration-color: red !important;
  color: unset !important;
}

.box-tbodytable.management-table-scroll.history-report {
  max-height: calc(100vh - 286px);
  overflow: auto;
}

.reporrt-date {
  width: 160px;
}

.reporrt-time {
  width: 100px;
}

.page-user-history-report .box-tbodytable {
  max-height: calc(100vh - 286px);
}

.page-user-history-report .box-theadtable tr th,
.page-user-history-report .box-theadtable tr td,
.page-user-history-report .box-tbodytable tr th,
.page-user-history-report .box-tbodytable tr td {
  min-width: 100px;
  max-width: 100px;
}

.page-user-history-report .box-theadtable tr th:nth-child(4),
  .page-user-history-report .box-theadtable tr td:nth-child(4),
  .page-user-history-report .box-tbodytable tr th:nth-child(4),
  .page-user-history-report .box-tbodytable tr td:nth-child(4) {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
}

.page-user-history-report .box-theadtable tr th:nth-child(9),
  .page-user-history-report .box-theadtable tr td:nth-child(9),
  .page-user-history-report .box-tbodytable tr th:nth-child(9),
  .page-user-history-report .box-tbodytable tr td:nth-child(9) {
  min-width: 150px;
  max-width: 150px;
  width: 15%;
}

.box-tbodytable.table-Item-status {
  max-height: calc(100vh - 422px);
  overflow: auto;
}

.page-item-status .box-CodeList .item-status-tbodytable {
  max-height: calc(100vh - 254px);
}

.page-item-status .box-CodeList .item-status-theadtable tr th,
.page-item-status .box-CodeList .item-status-theadtable tr td,
.page-item-status .box-CodeList .item-status-tbodytable tr th,
.page-item-status .box-CodeList .item-status-tbodytable tr td {
  min-width: 95px;
  max-width: 95px;
  width: 10%;
}

.page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(1), .page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(12), .page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(14), .page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(16), .page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(18),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(1),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(12),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(14),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(16),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(18),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(1),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(12),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(14),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(16),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(18),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(1),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(12),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(14),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(16),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(18) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.page-item-status .box-CodeList .item-status-theadtable tr th:nth-child(10),
  .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(10),
  .page-item-status .box-CodeList .item-status-tbodytable tr th:nth-child(10),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(10) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.page-item-status .box-CodeList .item-status-theadtable tr td,
.page-item-status .box-CodeList .item-status-tbodytable tr td {
  text-align: center;
}

.page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(7), .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(10), .page-item-status .box-CodeList .item-status-theadtable tr td:nth-child(11),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(7),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(10),
  .page-item-status .box-CodeList .item-status-tbodytable tr td:nth-child(11) {
  text-align: right;
}

.box-tbodytable.table-Log-line-status {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.page-log-status .box-CodeList .log-status-tbodytable {
  max-height: calc(100vh - 254px);
}

.page-log-status .box-CodeList .log-status-theadtable tr th,
.page-log-status .box-CodeList .log-status-theadtable tr td,
.page-log-status .box-CodeList .log-status-tbodytable tr th,
.page-log-status .box-CodeList .log-status-tbodytable tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(1), .page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(11), .page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(13), .page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(15), .page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(17), .page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(19),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(1),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(11),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(13),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(15),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(17),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(19),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(1),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(11),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(13),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(15),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(17),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(19),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(1),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(11),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(13),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(15),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(17),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(19) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.page-log-status .box-CodeList .log-status-theadtable tr th:nth-child(9),
  .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(9),
  .page-log-status .box-CodeList .log-status-tbodytable tr th:nth-child(9),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(9) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.page-log-status .box-CodeList .log-status-theadtable tr td,
.page-log-status .box-CodeList .log-status-tbodytable tr td {
  text-align: center;
}

.page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(7), .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(9), .page-log-status .box-CodeList .log-status-theadtable tr td:nth-child(10),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(7),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(9),
  .page-log-status .box-CodeList .log-status-tbodytable tr td:nth-child(10) {
  text-align: right;
}

.page-block .box-CodeList .block-status-tbodytable {
  max-height: calc(100vh - 254px);
}

.page-block .box-CodeList .block-status-theadtable tr th,
.page-block .box-CodeList .block-status-theadtable tr td,
.page-block .box-CodeList .block-status-tbodytable tr th,
.page-block .box-CodeList .block-status-tbodytable tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.page-block .box-CodeList .block-status-theadtable tr th:nth-child(1), .page-block .box-CodeList .block-status-theadtable tr th:nth-child(10), .page-block .box-CodeList .block-status-theadtable tr th:nth-child(12), .page-block .box-CodeList .block-status-theadtable tr th:nth-child(14), .page-block .box-CodeList .block-status-theadtable tr th:nth-child(16), .page-block .box-CodeList .block-status-theadtable tr th:nth-child(18),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(1),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(10),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(12),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(14),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(16),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(18),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(1),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(10),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(12),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(14),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(16),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(18),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(1),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(10),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(12),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(14),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(16),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(18) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.page-block .box-CodeList .block-status-theadtable tr th:nth-child(8),
  .page-block .box-CodeList .block-status-theadtable tr td:nth-child(8),
  .page-block .box-CodeList .block-status-tbodytable tr th:nth-child(8),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(8) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.page-block .box-CodeList .block-status-theadtable tr td,
.page-block .box-CodeList .block-status-tbodytable tr td {
  text-align: center;
}

.page-block .box-CodeList .block-status-theadtable tr td:nth-child(8), .page-block .box-CodeList .block-status-theadtable tr td:nth-child(9),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(8),
  .page-block .box-CodeList .block-status-tbodytable tr td:nth-child(9) {
  text-align: right;
}

.databe-locking {
  padding: 16px;
}

.locking {
  background: #ECECEC;
  padding: 16px;
}

.box-tbodytable.table-Page-status {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.box-CodeList .Page-status tr th,
.box-CodeList .Page-status tr td,
.box-CodeList .table-Page-status tr th,
.box-CodeList .table-Page-status tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

/*.box-CodeList .Page-status tr th:nth-child(1),*/
.box-CodeList .Page-status tr th:nth-child(9),
.box-CodeList .Page-status tr th:nth-child(11),
.box-CodeList .Page-status tr th:nth-child(13),
.box-CodeList .Page-status tr th:nth-child(15),
.box-CodeList .Page-status tr th:nth-child(17),
  /*.box-CodeList .Page-status tr td:nth-child(1),*/
  .box-CodeList .Page-status tr td:nth-child(9),
  .box-CodeList .Page-status tr td:nth-child(11),
  .box-CodeList .Page-status tr td:nth-child(13),
  .box-CodeList .Page-status tr td:nth-child(15),
  .box-CodeList .Page-status tr td:nth-child(17),
  .box-CodeList .table-Page-status tr th:nth-child(1),
  .box-CodeList .table-Page-status tr th:nth-child(9),
  .box-CodeList .table-Page-status tr th:nth-child(11),
  .box-CodeList .table-Page-status tr th:nth-child(13),
  .box-CodeList .table-Page-status tr th:nth-child(15),
  .box-CodeList .table-Page-status tr th:nth-child(17),
  .box-CodeList .table-Page-status tr td:nth-child(1),
  .box-CodeList .table-Page-status tr td:nth-child(9),
  .box-CodeList .table-Page-status tr td:nth-child(11),
  .box-CodeList .table-Page-status tr td:nth-child(13),
  .box-CodeList .table-Page-status tr td:nth-child(15),
  .box-CodeList .table-Page-status tr td:nth-child(17) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

.box-CodeList .Page-status tr th:nth-child(5), .box-CodeList .Page-status tr th:nth-child(6),
  .box-CodeList .Page-status tr td:nth-child(5),
  .box-CodeList .Page-status tr td:nth-child(6),
  .box-CodeList .table-Page-status tr th:nth-child(5),
  .box-CodeList .table-Page-status tr th:nth-child(6),
  .box-CodeList .table-Page-status tr td:nth-child(5),
  .box-CodeList .table-Page-status tr td:nth-child(6) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

.box-CodeList .Page-status tr th:nth-child(7), .box-CodeList .Page-status tr th:nth-child(8),
  .box-CodeList .Page-status tr td:nth-child(7),
  .box-CodeList .Page-status tr td:nth-child(8),
  .box-CodeList .table-Page-status tr th:nth-child(7),
  .box-CodeList .table-Page-status tr th:nth-child(8),
  .box-CodeList .table-Page-status tr td:nth-child(7),
  .box-CodeList .table-Page-status tr td:nth-child(8) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.box-CodeList .Page-status tr td,
.box-CodeList .table-Page-status tr td {
  text-align: center;
}

.box-CodeList .Page-status tr td:nth-child(7), .box-CodeList .Page-status tr td:nth-child(8),
  .box-CodeList .table-Page-status tr td:nth-child(7),
  .box-CodeList .table-Page-status tr td:nth-child(8) {
  text-align: right;
}

.box-tbodytable.table-Subject-status {
  max-height: calc(100vh - 254px);
  overflow: auto;
}

table.table.table-box.Subject-status thead tr th:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Subject-status thead tr th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Subject-status thead tr th:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Subject-status thead tr th:nth-child(5) {
  min-width: 125px;
  max-width: 125px;
  width: 10%;
}

table.table.table-box.Subject-status thead tr th:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Subject-status thead tr th:nth-child(7) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(8) {
  min-width: 80px;
  max-width: 80px;
  width: 7%;
}

table.table.table-box.Subject-status thead tr th:nth-child(9) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(10) {
  min-width: 60px;
  max-width: 60px;
  width: 7%;
}

table.table.table-box.Subject-status thead tr th:nth-child(11) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(12) {
  min-width: 90px;
  max-width: 90px;
  width: 7%;
}

table.table.table-box.Subject-status thead tr th:nth-child(13) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(14) {
  min-width: 80px;
  max-width: 80px;
  width: 7%;
}

table.table.table-box.Subject-status thead tr th:nth-child(15) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Subject-status thead tr th:nth-child(16) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(5) {
  min-width: 125px;
  max-width: 125px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(7) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(8) {
  min-width: 80px;
  max-width: 80px;
  width: 7%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(9) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(10) {
  min-width: 60px;
  max-width: 60px;
  width: 7%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(11) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(12) {
  min-width: 90px;
  max-width: 90px;
  width: 7%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(13) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(14) {
  min-width: 80px;
  max-width: 80px;
  width: 7%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(15) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Subject-status tbody tr td:nth-child(16) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.btn-site-status {
  background-color: #F5F5F5;
  color: #000;
  min-width: 80px;
}

.box-tbodytable.table-Site-status {
  max-height: calc(100vh - 254px);
  overflow: auto;
}

.modal-body.site-middle, .modal-body.site-text {
  padding: 1rem 1rem 0;
}

.site-left {
  width: 20%;
}

.site-right {
  width: 80%;
}

.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
  display: block;
}

#myInput {
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 14px;
  height: 30px;
  padding: 0 8px;
  border: none;
  border: 1px solid #169F85;
  width: 100%;
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #169F85;
  z-index: 1;
  margin: 0;
  padding: 12px;
  margin-left: -15px;
}

.dropdown-content.current {
  display: block;
}

.dropdown-content a {
  color: #333;
  font-weight: normal;
  padding: 4px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content-thead {
  background-color: #F3F0EA;
  min-width: 200px;
  max-width: 250px;
  overflow: auto;
  border: 1px solid #169F85;
  z-index: 1;
  margin: 0;
  padding: 12px;
}

.dropdown-content-thead.dropdown-w300 {
  min-width: 200px;
  max-width: 340px;
}

.dropdown-content-thead.dropdown-w370 {
  min-width: 200px;
  max-width: 370px;
}

.dropdown-content-thead .box-drop {
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
}

.dropdown-content-thead .box-drop .input-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.dropdown-content-thead .box-drop .input-calendar .form-control {
  width: 46%;
  border: 1px solid #169f85;
}

.dropdown-content-thead .box-drop .input-calendar span {
  padding: 0 4px;
}

.dropdown-content-thead .box-drop .calendar-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-content-thead .box-drop .calendar-time .form-control {
  width: 50%;
}

.dropdown-content-thead .box-drop .calendar-time .form-control.form-control-time {
  width: 30%;
  margin-left: 14px;
}

.dropdown-content-thead .about {
  background: #fff;
  border: 1px solid #ddd;
  margin-top: 12px;
  max-height: 200px;
  overflow-y: auto;
  padding: 6px;
}

.dropdown-content-thead .about:first-child {
  margin-top: 0;
}

.dropdown-content-thead .about ul li {
  padding: 3px 0;
}

.dropdown-content-thead .text-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.show {
  display: block;
}

table.table.table-box.Site-status thead tr th:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(3) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(4) {
  min-width: 125px;
  max-width: 125px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(6) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(7) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(8) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(9) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Site-status thead tr th:nth-child(10) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(11) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Site-status thead tr th:nth-child(12) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(13) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.Site-status thead tr th:nth-child(14) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Site-status thead tr th:nth-child(15) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(3) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(4) {
  min-width: 125px;
  max-width: 125px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(6) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(7) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(8) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(9) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(10) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(11) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(12) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(13) {
  min-width: 70px;
  max-width: 70px;
  width: 8%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(14) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Site-status tbody tr td:nth-child(15) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

.box-tbodytable.table-Query-managemnt {
  max-height: calc(100vh - 254px);
  overflow: auto;
}

table.table.table-box.Query-managemnt thead tr th {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(2) {
  min-width: 40px;
  max-width: 40px;
  width: 8%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(3) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(4) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(5) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(6) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(7) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(8) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(9) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(10) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(11) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(12) {
  min-width: 190px;
  max-width: 190px;
  width: 20%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(13) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.Query-managemnt thead tr th:nth-child(14) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
  width: 2%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(2) {
  min-width: 40px;
  max-width: 40px;
  width: 8%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(3) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(4) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(5) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(6) {
  min-width: 90px;
  max-width: 90px;
  width: 10%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(7) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(8) {
  min-width: 110px;
  max-width: 110px;
  width: 12%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(9) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(10) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(11) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(12) {
  min-width: 190px;
  max-width: 190px;
  width: 20%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(13) {
  min-width: 130px;
  max-width: 130px;
  width: 14%;
}

table.table.table-box.table-box.table-striped.Query-managemnt tbody tr td:nth-child(14) {
  min-width: 140px;
  max-width: 140px;
  width: 15%;
}

.box-slider {
  border: 1px solid #707070;
  padding: 40px 70px 60px 70px;
  position: relative;
}

.box-slider .slider-training {
  position: relative;
}

.box-slider .slider-training .item-slider img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  margin: auto;
}

.box-slider .slider-training .owl-nav {
  margin: 0;
}

.box-slider .slider-training .owl-nav .owl-prev {
  position: absolute;
  left: -60px;
  margin: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.box-slider .slider-training .owl-nav .owl-prev:hover {
  background: transparent;
}

.box-slider .slider-training .owl-nav .owl-prev span {
  font-size: 70px;
  color: #707070;
}

.box-slider .slider-training .owl-nav .owl-next {
  position: absolute;
  right: -60px;
  margin: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.box-slider .slider-training .owl-nav .owl-next:hover {
  background: transparent;
}

.box-slider .slider-training .owl-nav .owl-next span {
  font-size: 70px;
  color: #707070;
}

.box-slider .box-bottomslider {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
}

.box-slider .box-bottomslider .text-numberslider {
  text-align: center;
  padding-bottom: 6px;
}

.box-slider .box-bottomslider .box-buttonslider {
  position: absolute;
  right: 15px;
  bottom: 0px;
}

/*  */
.ant-input-number-disabled .ant-input-number-input,
.ant-calendar-picker-input.ant-input.ant-input-disabled,
.ant-radio-disabled .ant-radio-input,
.ant-radio-disabled+span,
.ant-checkbox-disabled .ant-checkbox-input,
.ant-select-disabled .ant-select-selection,
.ant-input.ant-input-disabled.form-control {
	cursor: context-menu !important;
}

.ant-form-item-control {
	line-height: 1.6 !important;
}

.ant-btn.btn-green:hover,
.ant-btn.btn-green:focus {
	color: #fff;
	background-color: #26B99A;
	border-color: #26B99A;
}

.wrapper .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #26B99A;
	border-color: #26B99A;
}

.wrapper .ant-checkbox-inner {
	width: 20px;
	height: 20px;
	border-radius: 4px;
}

.wrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.wrapper .ant-checkbox:hover .ant-checkbox-inner {
	border-color: 1px solid #d9d9d9;
	color: #fff;
	/* background-color: #26B99A; */
	border-color: #26B99A;
}

.wrapper .ant-checkbox-input:focus+.ant-checkbox-inner {
	border-color: #26B99A;
}

.loading-blur {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	display: none \9;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0.4;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	pointer-events: auto;
	content: '';
}

.main .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1ABC9C;
	border-color: #1ABC9C;
}

.main .ant-checkbox-inner::after {
	left: 30%;
}

tr.hidden-tr {
	display: none;
}

.text-error {
	font-size: 14px;
	color: #f5222d;
	margin-bottom: -1px;
}

.table-custom-input .icon-upto,
.table-custom-input .icon-downbottom,
.table-custom-input .icon-plusmini,
.table-custom-input .btn-more {
	margin-top: 5px;
}

.table-custom-input .ant-checkbox {
	margin-top: 5px;
}

.table-custom-input .icon-deletered,
.table-custom-input .icon-searchgreen {
	margin-top: 2px;
}

.table-property .table tbody tr:last-child td {
	border-bottom: 1px solid #ddd !important;
}

.box-Property .tablefixedhead .box-tbodytable {
	min-height: calc(100vh - 220px);
}

.main .ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.ant-checkbox-wrapper-disabled:hover .ant-checkbox-inner,
.ant-checkbox-wrapper-disabled .ant-checkbox:hover .ant-checkbox-inner {
	background-color: #f5f5f5 !important;
	border-color: #d9d9d9 !important;
}

.icon-disabled {
	background-color: #c3c3c3;
}

.menu-disabled {
	color: #c3c3c3 !important;
	cursor: context-menu !important;
}

.ant-modal-content .ant-modal-header {
	padding: 8px 15px;
}

.ant-modal-content .ant-modal-title {
	font-size: 18px;
	font-weight: bold;
}

.notification-success .ant-notification-notice-icon-success {
	bottom: 12px;
}

.notification-success .ant-notification-notice-message {
	display: none;
}

.grpagetion .page ul li:first-of-type a {
	color: #077CF8;
}

.grpagetion .page ul li:last-child a {
	color: #077CF8;
}

.grpagetion .page ul li a.is-first-page {
	color: #73879C;
	cursor: default;
}

.grpagetion .page ul li a.is-last-page {
	color: #73879C;
	cursor: default;
}

.ant-btn.btn-red-fl {
	background-color: #DE8282;
	border: 1px #DE8282 solid;
	color: #fff;
}

.main .ant-row .has-error .ant-form-explain {
	margin-top: 3px;
	font-size: 12px;
}

.main .ant-input-group-addon .ant-select-open .ant-select-selection,
.main .ant-input-group-addon .ant-select-focused .ant-select-selection {
	color: #1abc9c;
}

.main .ant-radio-input:focus+.ant-radio-inner {
	border-color: #1abc9c;
}

.main .ant-radio-wrapper:hover .ant-radio,
.main .ant-radio:hover .ant-radio-inner {
	border-color: #d9d9d9;
}

.main .ant-radio-checked::after {
	border: 1px solid #1abc9c;
}

.main .ant-radio-disabled::after {
	border: 1px solid #d9d9d9;
}

.main .ant-radio-inner::after {
	background-color: #1abc9c;
}

.main .ant-radio-checked .ant-radio-inner {
	border-color: #1abc9c;
	background: url(../../images/icon-radio.png) no-repeat 50%
}

.main .ant-radio-button-wrapper-checked {
	z-index: 1;
	color: #1abc9c;
	background: #fff;
	border-color: #1abc9c;
	box-shadow: -1px 0 0 0 #1abc9c;
}

.main .ant-radio-button-wrapper-checked::before {
	background-color: #1abc9c !important;
	opacity: 0.1;
}

.main .ant-radio-button-wrapper-checked:first-child {
	border-color: #1abc9c;
	box-shadow: none !important;
}

.main .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #1abc9c;
	border-color: #1abc9c;
}

.main .ant-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 18px;
	height: 18px;
	background-color: #fff;
	border-width: 1px;
	border-style: solid;
	border-radius: 100px;
	transition: all 0.3s;
}

.main .ant-radio-inner:hover {
	border-color: #d9d9d9;
}

.main .ant-radio-inner,
.main .ant-radio-inner::after,
.main .ant-radio-checked .ant-radio-inner::after {
	transition: none !important;
}

.main .ant-radio-checked::after {
	transition: none !important;
	animation: none !important;
}

.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
	background: none;
}

.ant-radio-checked.ant-radio-disabled .ant-radio-inner::after {
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
	top: 4px;
	left: 4px;
}

.main .ant-input:hover {
	border-color: #CCCCCC;
}

.ant-modal-content .ant-modal-body .modal-body .grpform-input .txt-label {
	width: 60px;
	margin-right: 10px;
	line-height: 30px;
}

.ant-modal-content .ant-modal-body .modal-body .grpform-input .frm-btn {
	width: 65px;
	margin-left: 10px;
}

.ant-modal-content .ant-modal-body {
	padding-top: 16px;
	padding-bottom: 16px;
}

.ant-modal-content .ant-modal-body {
	padding: 10px 15px;
}

.ant-modal-content .ant-modal-body .modal-body {
	padding: 0px;
}

.ant-modal-content .frm-input .form-control.form-control-danger {
	border: 1px #ff3b30 solid;
	background: #ffeae8;
}

.ant-modal-content .ant-modal-header {
	padding: 6px 15px;
	color: #fff;
	background: #445466;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 0.3rem 0.3rem 0 0;
}

.modal-confirm-custom .ant-modal-body .modal-body .grp-buttonfooter .btn-gray:focus,
.modal-confirm-custom .ant-modal-body .modal-body .grp-buttonfooter .btn-gray.focus {
    border: 2px #169F85 solid;
}
.ant-modal-content .ant-modal-title {
	color: #fff;
}

.modal-confirm-custom .ant-modal-content .grpform-input {
	padding: 5px 0;
}

.modal-confirm-custom .ant-modal-content .frm-input {
	width: 300px;
}

.ant-modal-content .modal-confirm-content {
	padding: 20px 30px;
	text-align: center;
}

.ant-modal-content {
	border-radius: 0.4rem !important;
}

.ant-modal-content .ant-modal-close-x {
	display: none;
}

.ant-modal-close::before {
	content: "×";
	position: absolute;
	right: 10px;
	top: 2px;
	padding: 0;
	margin: 0;
	color: #fff;
	opacity: 1;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	text-shadow: 0 1px 0 #fff;
}

.form-control:disabled,
.form-control[readonly] {
	cursor: context-menu;
}

.dropdown .page-disable.form-control {
	background-color: #e9ecef;
	opacity: 1;
	cursor: context-menu;
}

.dropdown .page-disable.form-control:hover {
	color: #000;
}

.mr-top-30 {
	margin-top: 30px;
}

.action {
	display: none;
}
/**
 * style css antd datepicker
 */
th[role="columnheader"] {
	color: #26B99A;
	font-weight: bold !important;
}

th[role="columnheader"]:first-child > span {
	color: #ff0000;
	font-weight: bold;
}

th[role="columnheader"]:last-child > span {
	color: #000AFF;
	font-weight: bold;
}


tbody.ant-calendar-tbody tr[role="row"] td[role="gridcell"]:first-child > div.ant-calendar-date {
	color: #ff0000;
}

tbody.ant-calendar-tbody tr[role="row"] td[role="gridcell"]:last-child > div.ant-calendar-date {
	color: #000AFF;
}


.ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-selected-end-date .ant-calendar-date {
	color: #fff !important;
	background-color: #204d74 !important;;
	border-color: #122b40 !important;;
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
	font-weight: bold !important;
}

/*style button convert to Link*/
a.btn.btn-green.mr-8.react-link, a.btn.btn-dark-brown.mr-8.react-link {
	line-height: 26px !important;
}
a.btn.btn-green.mr-8.react-link:hover {
	color: #FFF;
}

.box-theadtable .dropdown-modal .custom-checkbox {
  color:#73879C;
}
.filtered {
  color: red !important;
}
.modal-confirm-custom .ant-modal-header{
  background: #2A3F54;
  color: #fff;
  padding: 5px 15px;
  font-size: 14px;
}
.modal-confirm-custom .ant-modal-content .ant-modal-title {
  font-size: 14px;
  font-weight: 500;
}

.modal-confirm-custom .modal-confirm-content {
  padding: 5px;
}

.text-throught a {
	text-decoration-color: red !important;
	/*color: red !important;*/
	text-decoration: none;
}

.filtered .dropbtn:after {
  color: red;
}

.modal-visit .btn-gray:hover {
  background-color: #F5F5F5;
  border: 1px #169F85 solid;
  color: #333;
}

.has-filter {
  position: relative;
}
.tablefixedhead .box-theadtable .dropdown-modal {
  position: absolute;
  top: 29px;
  right: 0px;
  z-index: 9;
}
.dropdown .dropbtn:hover {
  color: white;
}
.input-border-blue {
  border: 1px solid #169F85 !important;
}
.box-drop .content-input .form-control {
  width: 95%;
}
.box-drop .content-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  color: #000;
}
.box-drop .content-input span {
  margin-left: 5px;
  margin-right: 5px;
  color: #333;
}
.box-drop .content-input .ant-calendar-picker {
  margin-left: 0;
  margin-right: 0;
}
.box-drop .content-input .ant-calendar-picker-input {
  border: 1px solid #169F85 !important;
}
.sidebar .nav > ul > li.no-subnav > a {
  cursor: pointer !important;
}

.width-20 {
  width: 20px;
}
.pdd-right-20 {
  padding-right: 20px;
}
.table.table-has-checkbox .custom-checkbox {
  padding-left: 20px;
}

td.text-left {
	text-align: left !important;
}
td.text-right {
	text-align: right !important;
}

.page-user-management .management-table tr th.w100,
.page-user-management .management-table tr td.w100 {
	min-width: 100px !important;
}
.sidebar .nav > ul > li.no-subnav > a.no-url, .sidebar .nav > ul > li a.no-url {
  cursor: context-menu !important;
}

.sidebar .nav > ul > li a.active-menu {
  background: #FCC80F;
  font-weight: bold;
}
.mt-15 {
  margin-top: 15px;
}
.dropdown-modal input[type="number"]::-webkit-outer-spin-button, .dropdown-modal input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-modal input[type="number"] {
  -moz-appearance: textfield;
}

.custom-control-input:disabled~.custom-control-description {
	cursor: context-menu !important;
}

.has-query-reply {
  position: absolute;
  top: calc(50% + 8px);
  left: -16px;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  background: #FFCC00;
}

.has-query {
  position: absolute;
  top: 0;
  left: -16px;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  background: #DD0000;
}
.accordion-sub .has-query {
  top: 2px;
  left: -10px;
}
.accordion-sub .has-query-reply {
  top: calc(50% + 2px);
  left: -10px;
}

.text-normal {
  font-weight: normal;
}
.site-mb-bottom .container-wrap .form-left-site  {
    margin-top: 4px;
}
.site-mb-bottom .container-wrap .form-left-site .label-red {
    margin-top: 3px;
}
.site-mb-bottom .container-wrap .form-left-r {
  line-height: 30px;
}


.ant-btn.btn-red-fl:hover,
.ant-btn.btn-red-fl:hover {
	background-color: #DE8282;
	border: 1px #DE8282 solid;
	color: #fff;
}

.icon-searchgreen.disabled{
	cursor: context-menu;
}
.has-filter .dropdown-modal .btn-gray {
  width: 100px
}
.has-filter .color-black {
  color: #333;
}
.custom-button-calendar {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
}

.custom-button-calendar .btn-gray {
  font-size: 11px;
  background-color: #fff;
  color: #222;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  height: 28px;
  margin-left: 4px;
  margin-right: 4px;
}
.custom-button-calendar .btn-gray:hover, .custom-button-calendar .btn-gray:focus {
  background-color: #fff;
  color: #222;
  border-color: #26B99A;
}
.custom-button-calendar .is-format {
  background-color: #26B99A;
  color: #fff;
}

.custom-button-calendar .ant-btn.disabled, .ant-btn[disabled], .custom-button-calendar .ant-btn.disabled:hover,  .custom-button-calendar .ant-btn.disabled:focus {
  cursor: context-menu ;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  opacity: 1;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.border-red, .border-red.ant-calendar-picker input {
  border-color: #ff3b30 !important    ;
}
.custom-time .ant-select-arrow .ant-select-arrow-icon {
  right: 9px;
  top: 13px;
  font-size: 14px;
}
.modal-confirm-custom .grp-buttonfooter button, .modal-confirm-custom .btn-comfim button {
  width: 90px;
}

.table tbody tr td.pre-line {
  white-space: pre-line;
}

/*style css Modal confirm Password*/
.confirm-pwd button.btn.btn-gray{
	min-width: 115px !important;
	padding: 0 !important;
}

.confirm-pwd .has-error .ant-form-explain,
.confirm-pwd .has-error .ant-form-split {
	text-align: left !important;
	font-size: 12px !important;
	padding-top: 2px !important;
}

.grpagetion .page ul li a.text-page, .grpagetion .page ul li a.text-page {
  color: #73879C;
}

/**
 * Style css modal auditTrail Common
 */

.modal-audit-trail {
	padding-bottom: 0 !important;
}
.modal-audit-trail .ant-modal-content{
	border-radius: 0 !important;
	padding: 5px !important;
	border: 1px solid #707070;
}

.modal-audit-trail .ant-modal-content .ant-modal-close::before {
  top: 7px !important;
}

.modal-audit-trail .ant-modal-content .ant-modal-header {
	border-radius: 0 !important;
	color: #FFF !important;
	background-color: #F6A828;
}
.modal-audit-trail .ant-modal-content .ant-modal-header .ant-modal-title {
	font-size: 16px;
}

.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body {
	padding: 0 !important;
	padding-top: 5px !important;
}

.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .modal-confirm-content {
	padding: 0 !important;
}

.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .modal-confirm-content .table.table-box th {
	color: #0088FF !important;
	background-color: #f6f6f6 !important;
}

.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .modal-confirm-content .table.table-box td {
	color: #000 !important;
}

.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .modal-confirm-content #audit-trail {
	max-height: 300px;
}
.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .m-footer button.btn.btn-close {
	width: 100px;
	border: 1px solid #707070;
	border-color: #4BB19C;
	border-radius: 0 !important;
	opacity: 1 !important;
}
.modal-audit-trail .ant-modal-content .ant-modal-body .modal-body .m-footer {
	padding: 10px;
}

#auditTrailList .box-tbodytable{
	height: 300px !important;
}

#auditTrail > table {
	border-bottom: 1px solid #DDD !important;
}
/*#auditTrailList table th.no,*/
/*#auditTrailList table td.no {*/
	/*width: 50px !important;*/
/*}*/

/*#auditTrailList table th.loginId,*/
/*#auditTrailList table td.loginId {*/
	/*width: 150px !important;*/
/*}*/

/*#auditTrailList table th.userName,*/
/*#auditTrailList table td.userName {*/
	/*width: 150px !important;*/
/*}*/

/*#auditTrailList table th.site,*/
/*#auditTrailList table td.site{*/
	/*width: 150px !important;*/
/*}*/

/*#auditTrailList table th.siteName,*/
/*#auditTrailList table td.siteName {*/
	/*width: 150px !important;*/
/*}*/

#auditTrailList table th.w50,
#auditTrailList table td.w50{
    width: 50px !important;
	min-width: 50px !important;
	max-width: 50px !important;
}

#auditTrailList table th.w100,
#auditTrailList table td.w100{
    width: 100px !important;
	min-width: 100px !important;
	max-width: 100px !important;
}

#auditTrailList table th.w150,
#auditTrailList table td.w150{
    width: 150px !important;
	min-width: 150px !important;
	max-width: 150px !important;
}

#auditTrailList table th.w200,
#auditTrailList table td.w200{
	width: 200px !important;
	min-width: 200px !important;
	max-width: 200px !important;
}

#auditTrailList table th.w250,
#auditTrailList table td.w250{
	width: 250px !important;
	min-width: 250px !important;
	max-width: 250px !important;
}

.cursor {
	cursor: pointer !important;
}

.cursor-default {
	cursor: default !important;
}
.modal-visit .btn-gray {
  background-color: #F5F5F5;
  border: 1px #169F85 solid;
  color: #000;
}
.filter-list-checkbox  label.ant-checkbox-group-item span:last-of-type {
  display: inline-block;
  max-width: 157px;
  padding-left: 5px;
  padding-right: 5px;
}

.custom-checkbox .custom-control-input[disabled]:checked ~ .custom-control-indicator {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23bbb' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.ant-modal-title {
    min-height: 22px;
}

.user-info-menu.dropdown-menu.dropdown-menu-right.show {
    font-size: 14px;
}

.language-dropdown .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  color: #333 !important;
  font-size: 14px;
}

.popover-filter label.ant-checkbox-group-item.ant-checkbox-wrapper{
    margin-right: 0;
}

.language-dropdown .ant-dropdown-menu{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    position: fixed;
}

.language-dropdown {
    top: 29px !important;
}

.top-nav .navbar-right .dropdown-menu ul li a:hover {
    background-color: #e6f7ff;
}

.fs-12 {
  font-size: 12px;
}

.mt-4 {
  margin-top: 4px;
}